import { SoQLType } from 'common/types/soql';
export enum SuggestedValuesType { // referred to as Allowed Values in the UI
  ANY_VALUES = 'any_values',
  SPECIFIC_VALUES = 'specific_values',
  VALUES_FROM_COLUMN = 'values_from_column'
}

export type ClientContextVariable = ClientContextVariableCreate & {
  inherited: boolean;
  viewId: string;
  overrideValue?: string;
};

export type ClientContextVariableCreate = {
  name: string;
  displayName: string;
  dataType: SoQLType;
  defaultValue: string;
  suggestedValuesType?: SuggestedValuesType; // defaults to ANY_VALUES
  suggestedValues?: SpecificValues | ColumnValues | null // this will kind of be required
};

export type SpecificValue = {
  value: string;
  displayName?: string;
};

export type SpecificValues = {
  valueList: SpecificValue[];
};

export type ColumnValues = {
  columnId: string;
};

export interface DateClientContextVariable extends ClientContextVariable {
  dataType: SoQLType.SoQLFloatingTimestampT | SoQLType.SoQLFixedTimestampT;
}

export interface BooleanClientContextVariable extends ClientContextVariable {
  dataType: SoQLType.SoQLBooleanT;
}
