/**
 * !!!WARNING!!!
 *
 * This file is not automatically generated, but it should match, more or less, the
 * structure serialized by the SoQL Compiler on the QueryCompilerChannel. Adding
 * new things here that do not have an analog in SoQL Reference will cause future
 * pain. If you're not sure what that means, that is OK, please reach out to
 * #rainbows-internal to get it sorted out.
 */

import * as _ from 'lodash';
import type { Newtype } from 'common/types/newType';
import * as NewType from 'common/types/newType';
import { CatalogDataType } from 'common/types/catalog/views';

export enum CompoundOp {
  QueryPipe = 'QUERYPIPE',
  Union = 'UNION',
  UnionAll = 'UNION ALL',
  Intersect = 'INTERSECT',
  Minus = 'MINUS'
}

export interface Compound<T> {
  op: CompoundOp;
  left: BinaryTree<T>;
  right: BinaryTree<T>;
  type: 'compound';
}

export interface Leaf<T> {
  value: T;
  type: 'leaf';
}

export type BinaryTree<T> = Compound<T> | Leaf<T>;

export const isCompound = <T>(tree: BinaryTree<T>): tree is Compound<T> => tree.type === 'compound';
export const isLeaf = <T>(tree: BinaryTree<T>): tree is Leaf<T> => tree.type === 'leaf';
export const leaf = <T>(value: T): Leaf<T> => ({ type: 'leaf', value });

export enum Hint {
  Materialized = 'materialized',
  NoRollup = 'no_rollup',
  NoChainMerge = 'no_chain_merge'
}

export enum SoQLType {
  SoQLNumberT = 'number',
  SoQLTextT = 'text',
  /** This is core's type for a boolean column or client context variable. */
  SoQLBooleanT = 'checkbox',
  /** This is the data stack's type for a boolean column or client context variable. */
  SoQLBooleanAltT = 'boolean',
  /** This is core's type for a fixed timestamp column. */
  SoQLFixedTimestampT = 'date',
  /** This is the data stack's type for a fixed timestamp column. */
  SoQLFixedTimestampAltT = 'fixed_timestamp',
  /** This is core's type for a floating timestamp column or client context variable. */
  SoQLFloatingTimestampT = 'calendar_date',
  /** This is the data stack's type for a floating timestamp column or client context variable. */
  SoQLFloatingTimestampAltT = 'floating_timestamp',
  SoQLURLT = 'url',
  SoQLLocationT = 'location',
  SoQLPointT = 'point',
  SoQLLineT = 'line',
  SoQLPolygonT = 'polygon',
  SoQLMultiPointT = 'multipoint',
  SoQLMultiLineT = 'multiline',
  SoQLMultiPolygonT = 'multipolygon',
  SoQLJsonT = 'json'
}

export const isSortable = (st: SoQLType): boolean =>
  st === SoQLType.SoQLNumberT ||
  st === SoQLType.SoQLTextT ||
  st === SoQLType.SoQLBooleanT ||
  st === SoQLType.SoQLBooleanAltT ||
  st === SoQLType.SoQLFixedTimestampT ||
  st === SoQLType.SoQLFixedTimestampAltT ||
  st === SoQLType.SoQLFloatingTimestampT ||
  st === SoQLType.SoQLFloatingTimestampAltT ||
  st === SoQLType.SoQLURLT;

export const isGeospatial = (st: SoQLType) =>
  st === SoQLType.SoQLPointT ||
  st === SoQLType.SoQLLineT ||
  st === SoQLType.SoQLPolygonT ||
  st === SoQLType.SoQLMultiPointT ||
  st === SoQLType.SoQLMultiLineT ||
  st === SoQLType.SoQLMultiPolygonT;

export enum SoQLFunCall {
  Between = '#BETWEEN',
  Contains = 'contains',
  CountStar = 'count/*',
  In = '#IN',
  IsNotNull = '#IS_NOT_NULL',
  IsNull = '#IS_NULL',
  Like = '#LIKE',
  NotBetween = '#NOT_BETWEEN',
  NotIn = '#NOT_IN',
  NotLike = '#NOT_LIKE',
  StartsWith = 'starts_with',
  Median = 'median',
  CaselessContains = 'caseless_contains',
  CaselessIs = 'caseless_eq',
  CaselessIsNot = 'caseless_ne',
  CaselessNotOneOf = 'caseless_not_one_of',
  CaselessOneOf = 'caseless_one_of',
  CaselessStartsWith = 'caseless_starts_with'
}

export enum TypedFunCall {
  CountStar = 'count(*)',
  Median = 'median_disc'
}

// it uses nbe names on the way in and obe names on the way out :ugh:
export const usingNBEName = (st: SoQLType): SoQLType => {
  // argh this is so frustrating.
  if (st === SoQLType.SoQLFixedTimestampT) return SoQLType.SoQLFixedTimestampAltT;
  if (st === SoQLType.SoQLFloatingTimestampT) return SoQLType.SoQLFloatingTimestampAltT;
  if (st === SoQLType.SoQLBooleanT) return SoQLType.SoQLBooleanAltT;
  return st;
};

export interface Position {
  line: number;
  column: number;
}

export type TableQualifier = null | string;
export interface ColumnRef {
  type: 'column_ref';
  value: string;
  qualifier: TableQualifier;
  position?: Position;
}

export interface WindowFunctionInfo {
  partitions: Expr[];
  orderings: OrderBy[];
  frames: Expr[];
}

interface TypedWindowFunctionInfo {
  partitions: TypedExpr[];
  orderings: TypedOrderBy[];
  frames: TypedExpr[];
}

export interface FunCall {
  type: 'funcall';
  function_name: string;
  args: Expr[];
  window: WindowFunctionInfo | null;
  position?: Position;
}

export interface Let {
  type: 'let';
  clauses: Binding[];
  body: Expr;
  position?: Position;
}

export interface Parameter {
  type: 'param';
  name: string;
  table?: TableQualifier;
  position?: Position;
}

export interface Binding {
  type: 'binding';
  qualifier: TableQualifier;
  name: string;
  expr: Expr;
  position?: Position;
}

interface SoQLLiteral<T, V> {
  type: T;
  value: V;
  position?: Position;
}

export type SoQLLiteralExpr = SoQLLiteral<'soql_literal', string>;
export type SoQLStringLiteral = SoQLLiteral<'string_literal', string>;
export type SoQLNumberLiteral = SoQLLiteral<'number_literal', string>;
export type SoQLBooleanLiteral = SoQLLiteral<'boolean_literal', boolean>;
export type SoQLNullLiteral = SoQLLiteral<'null_literal', null>;

export type Literal =
  | SoQLLiteralExpr
  | SoQLStringLiteral
  | SoQLNumberLiteral
  | SoQLBooleanLiteral
  | SoQLNullLiteral;

export type Expr = ColumnRef | FunCall | Let | Literal | Parameter | Binding;

export interface TypedSoQLFunCall {
  type: 'funcall';
  function_name: string;
  args: TypedExpr[];
  window: TypedWindowFunctionInfo | null;
  soql_type: SoQLType;
}

// this is a little goofy but it is here for consistency
export type TypedSoQLColumnRef = ColumnRef & { soql_type: SoQLType };
export type TypedSoQLStringLiteral = SoQLStringLiteral & { soql_type: SoQLType };
export type TypedSoQLNumberLiteral = SoQLNumberLiteral & { soql_type: SoQLType };
export type TypedSoQLBooleanLiteral = SoQLBooleanLiteral & { soql_type: SoQLType };
export type TypedSoQLNullLiteral = SoQLNullLiteral & { soql_type: null };

export type TypedSoQLLiteral =
  | TypedSoQLStringLiteral
  | TypedSoQLNumberLiteral
  | TypedSoQLBooleanLiteral
  | TypedSoQLNullLiteral;
export type TypedExpr = TypedSoQLColumnRef | TypedSoQLLiteral | TypedSoQLFunCall;

export const isColumnRef = (x: Expr): x is ColumnRef => {
  return x.type === 'column_ref';
};
export const isFunCall = (x: Expr): x is FunCall => {
  return x.type === 'funcall';
};
export const isURL = (x: Expr): boolean => {
  return 'url' in x;
};
export const isLet = (x: Expr): x is Let => x.type === 'let';
export const isBinding = (x: Expr): x is Binding => x.type === 'binding';
export const isParameter = (x: Expr): x is Parameter => x.type === 'param';

export const isLiteral = (x: Expr): x is Literal => {
  return (
    x.type === 'string_literal' ||
    x.type === 'soql_literal' ||
    x.type === 'number_literal' ||
    x.type === 'boolean_literal' ||
    x.type === 'null_literal'
  );
};

export const isStringLiteral = (x: Expr): x is SoQLStringLiteral => {
  return x.type === 'string_literal';
};
export const isSoqlLiteral = (x: Expr): x is SoQLLiteralExpr => {
  return x.type === 'soql_literal';
};
export const isNumberLiteral = (x: Expr): x is SoQLNumberLiteral => {
  return x.type === 'number_literal';
};
export const isBooleanLiteral = (x: Expr): x is SoQLBooleanLiteral => {
  return x.type === 'boolean_literal';
};
export const isNullLiteral = (x: Expr): x is SoQLNullLiteral => {
  return x.type === 'null_literal';
};

export const isTypedColumnRef = (x: TypedExpr): x is TypedSoQLColumnRef => {
  return !_.isUndefined(x.soql_type) && x.type === 'column_ref';
};
export const isTypedFunCall = (x: TypedExpr): x is TypedSoQLFunCall => {
  return !_.isUndefined(x.soql_type) && x.type === 'funcall';
};
export const isTypedLiteral = (x: TypedExpr): x is TypedSoQLLiteral => {
  return (
    !_.isUndefined(x.soql_type) &&
    (x.type === 'string_literal' ||
      x.type === 'number_literal' ||
      x.type === 'boolean_literal' ||
      x.type === 'null_literal')
  );
};

export const isTypedStringLiteral = (x: TypedExpr): x is TypedSoQLStringLiteral => {
  return !_.isUndefined(x.soql_type) && x.type === 'string_literal';
};
export const isTypedNumberLiteral = (x: TypedExpr): x is TypedSoQLNumberLiteral => {
  return !_.isUndefined(x.soql_type) && x.type === 'number_literal';
};
export const isTypedBooleanLiteral = (x: TypedExpr): x is TypedSoQLBooleanLiteral => {
  return !_.isUndefined(x.soql_type) && x.type === 'boolean_literal';
};
export const isTypedNullLiteral = (x: TypedExpr): x is TypedSoQLNullLiteral => {
  return !_.isUndefined(x.soql_type) && x.type === 'null_literal';
};

export const isColumnEqualIgnoringPosition = (l: ColumnRef, r: ColumnRef): boolean => {
  // for many operations, we just want to see if some view column references some node in the AST, so
  // we want to exclude position information because it's not relevant
  return _.isEqual({ value: l.value, qualifier: l.qualifier }, { value: r.value, qualifier: r.qualifier });
};

export const isLiteralEqualIgnoringPosition = (l: Literal, r: Literal): boolean => {
  return l.type === r.type && l.value === r.value;
};

export const isFunCallEqualIgnoringPosition = (l: FunCall, r: FunCall): boolean => {
  return (
    l.function_name == r.function_name &&
    _.every(
      _.zip(l.args, r.args),
      ([larg, rarg]) => larg && rarg && isExpressionEqualIgnoringPosition(larg, rarg)
    )
  );
};

export const isParamEqualIgnoringPosition = (l: Parameter, r: Parameter): boolean => {
  return _.isEqual({ name: l.name, table: l.table }, { name: r.name, table: r.table });
};

export const isExpressionEqualIgnoringPosition = (l: Expr, r: Expr): boolean => {
  if (isColumnRef(l) && isColumnRef(r)) return isColumnEqualIgnoringPosition(l, r);
  if (isLiteral(l) && isLiteral(r)) return isLiteralEqualIgnoringPosition(l, r);
  if (isFunCall(l) && isFunCall(r)) return isFunCallEqualIgnoringPosition(l, r);
  if (isParameter(l) && isParameter(r)) return isParamEqualIgnoringPosition(l, r);
  return false;
};

type Traversable = Expr | null;
export function traverseExpr<T>(node: Traversable, acc: T, fun: (n: Traversable, a: T) => T): T {
  if (node && node.type === 'funcall') {
    return fun(
      node,
      node.args.reduce((nodeAcc, subnode) => traverseExpr(subnode, nodeAcc, fun), acc)
    );
  } else if (node && isLet(node)) {
    const clausesAcc = node.clauses.reduce((nodeAcc, clause) => traverseExpr(clause, nodeAcc, fun), acc);
    const bodyAcc = traverseExpr(node.body, clausesAcc, fun);
    return fun(node, bodyAcc);
  } else if (node && isBinding(node)) {
    const exprAcc = traverseExpr(node.expr, acc, fun);
    return fun(node, exprAcc);
  } else if (node && isParameter(node)) {
    return fun(node, acc);
  } else {
    return fun(node, acc);
  }
}

export function mapExpr(node: Expr, fun: (n: Expr) => Expr): Expr {
  if (node && node.type === 'funcall') {
    return fun({
      ...node,
      args: node.args.map((a) => mapExpr(a, fun))
    });
  } else if (node && isLet(node)) {
    return fun({
      ...node,
      clauses: node.clauses.map((clause) => ({
        ...clause,
        expr: mapExpr(clause.expr, fun)
      })),
      body: mapExpr(node.body, fun)
    });
  } else if (node && isBinding(node)) {
    return {
      ...node,
      expr: mapExpr(node.expr, fun)
    };
  } else {
    return fun(node);
  }
}

export const nullLiteral: SoQLNullLiteral = { type: 'null_literal', value: null };
export const typedNullLiteral: TypedSoQLNullLiteral = { type: 'null_literal', value: null, soql_type: null };

export const NoPosition = { line: 0, column: 0 };

interface SelectedPosition {
  line: number;
  column: number;
}

export interface NamePosition {
  name: string;
  position: SelectedPosition;
}

export interface UnAnalyzedSelectedExpression {
  expr: Expr;
  name: null | NamePosition;
}

export interface StarSelection {
  qualifier: null | string;
  exceptions: ColumnRef[];
}

export interface UnAnalyzedSelection {
  exprs: UnAnalyzedSelectedExpression[];
  all_system_except: null | StarSelection;
  all_user_except: StarSelection[];
}

export interface OrderBy {
  ascending: boolean;
  expr: Expr;
  null_last: boolean;
}

export interface TypedOrderBy {
  ascending: boolean;
  expr: TypedExpr;
  null_last: boolean;
}

export type JoinType = 'JOIN' | 'LEFT OUTER JOIN' | 'RIGHT OUTER JOIN' | 'FULL OUTER JOIN';

export interface TableName {
  name: string;
  alias: string | null;
}

export const reservedTableNames = ['_this', '_single_row'];

export interface JoinByFromTable {
  type: 'from_table';
  from_table: TableName;
}

export interface JoinByJoinFunc {
  type: 'join_function';
  join_function: {
    table_name: TableName;
    params: Expr[];
    position: Position;
  };
}

// UnanalyzedJoin
export interface JoinBySubSelect {
  type: 'sub_select';
  sub_select: {
    selects: BinaryTree<UnAnalyzedAst>;
    alias: string;
  };
}

// AnalyzedJoin
export interface JoinBySubAnalysis {
  type: 'sub_analysis';
  sub_analysis: {
    analyses: BinaryTree<AnalyzedAst>;
    alias: string;
  };
}

// This is kind of a mess, since it's mixing unanalyzed with analyzed, but it's not worth pulling it apart right now.
type JoinSelect = JoinByFromTable | JoinBySubSelect | JoinBySubAnalysis | JoinByJoinFunc;
export const isJoinByFromTable = (joinSelect: JoinSelect): joinSelect is JoinByFromTable =>
  joinSelect.type === 'from_table';
export const isJoinBySubSelect = (joinSelect: JoinSelect): joinSelect is JoinBySubSelect =>
  joinSelect.type === 'sub_select';
export const isJoinBySubAnalysis = (joinSelect: JoinSelect): joinSelect is JoinBySubAnalysis =>
  joinSelect.type === 'sub_analysis';
export const isJoinByJoinFunc = (joinSelect: JoinSelect): joinSelect is JoinByJoinFunc =>
  joinSelect.type === 'join_function';

export interface Join<E> {
  on: E;
  type: JoinType;
  lateral: boolean;
}

export type UnAnalyzedJoin = Join<Expr> & {
  from: JoinByFromTable | JoinBySubSelect | JoinByJoinFunc;
};

export type Indistinct = 'indistinct';
export type FullyDistinct = 'fully_distinct';

export interface DistinctOn {
  distinct_on: Expr[];
}

export interface TypedDistinctOn {
  distinct_on: TypedExpr[];
}

export type Distinct = boolean | Indistinct | FullyDistinct | DistinctOn;
export type TypedDistinct = boolean | Indistinct | FullyDistinct | TypedDistinctOn;

export const distinctIsDistinctOn = (distinct: Distinct): distinct is DistinctOn => {
  return !(distinct as DistinctOn).distinct_on === undefined;
};

export interface UnAnalyzedAst {
  selection: UnAnalyzedSelection;
  hints: Hint[];
  from: TableName | null;
  where: Expr | null;
  group_bys: Expr[];
  order_bys: OrderBy[];
  joins: UnAnalyzedJoin[];
  search: string | null;
  having: Expr | null;
  distinct: Distinct;
  limit: number | null;
  offset: number | null;
  // TODO: work with IR to remove this. It does not belong here!
  // https://socrata.atlassian.net/browse/EN-49418
  legacyWhereClause?: string | null;
}

export interface AnalyzedSelectedExpression {
  expr: TypedExpr;
  name: string;
}

export type UnAnalyzedCompoundAst = BinaryTree<UnAnalyzedAst>;

export type AnalyzedJoin = Join<TypedExpr> & {
  from: JoinByFromTable | JoinBySubAnalysis;
};
export interface AnalyzedAst {
  selection: AnalyzedSelectedExpression[];
  hints: Hint[];
  from: TableName | null;
  where: TypedExpr | null;
  group_bys: TypedExpr[];
  order_bys: TypedOrderBy[];
  joins: AnalyzedJoin[];
  search: string | null;
  having: TypedExpr | null;
  distinct: TypedDistinct;
  limit: number | null;
  offset: number | null;
}

export type AnalyzedCompoundAst = BinaryTree<AnalyzedAst>;

export type TypedJoin = Join<TypedExpr> & {
  from: JoinByFromTable | JoinBySubSelect | JoinByJoinFunc; // TODO: These need typed versions too.
};

export interface NamedExpr {
  expr: TypedExpr;
  name: null | NamePosition;
}

export interface Selection {
  exprs: NamedExpr[];
  all_system_except: null | StarSelection;
  all_user_except: StarSelection[];
}

export interface TypedSelect {
  selection: Selection;
  hints: Hint[];
  from: TableName | null;
  where: TypedExpr | null;
  group_bys: TypedExpr[];
  order_bys: TypedOrderBy[];
  joins: TypedJoin[];
  search: string | null;
  having: TypedExpr | null;
  distinct: TypedDistinct;
  limit: number | null;
  offset: number | null;
}

export interface SoQLRendering extends Newtype<{ readonly q: unique symbol }, string> {}
export const soqlRendering = NewType.makeNewtype<SoQLRendering>();

export interface RunnableSoQLRendering extends Newtype<{ readonly q: unique symbol }, string> {}
export const runnableSoQLRendering = NewType.makeNewtype<RunnableSoQLRendering>();

export interface Generic {
  kind: 'variable';
  type: string;
}
export interface Fixed {
  kind: 'fixed';
  type: SoQLType;
}
export interface Wildcard {
  kind: 'wildcard';
  type: '?';
}

export const isGeneric = (x: Type): x is Generic => x.kind !== undefined && x.kind === 'variable';
export const isFixed = (x: Type): x is Fixed => x.kind !== undefined && x.kind === 'fixed';
export const isWildcard = (x: Type): x is Wildcard => x.kind !== undefined && x.kind === 'wildcard';

export type Type = Generic | Fixed | Wildcard;
export interface TypeConstraints {
  [constraintName: string]: SoQLType[];
}

export interface ExpressionDocExample {
  type: 'expression_example';
  explanation: string;
  expression: string;
  result: string;
}

export interface QueryDocExample {
  type: 'query_example';
  explanation: string;
  query: string;
  tryit: string;
}

export type DocumentationExample = QueryDocExample | ExpressionDocExample;

export enum FunctionDocStatus {
  Normal = 'normal',
  Deprecated = 'deprecated',
  Hidden = 'hidden'
}
export interface Documentation {
  docstring: string;
  examples: DocumentationExample[];
  status: FunctionDocStatus;
}

export interface FunSpec {
  name: string;
  identity: string;
  constraints: TypeConstraints;
  operator: boolean;
  result: Type;
  sig: Type[];
  variadic: Type[];
  is_aggregate: boolean;
  doc?: string;
  doc_v2?: Documentation;
}

export type Scope = FunSpec[];

export const catalogDataTypeToSoQLType = (catalogDataType: CatalogDataType): SoQLType => {
  switch (catalogDataType) {
    case CatalogDataType.Text:
      return SoQLType.SoQLTextT;
    case CatalogDataType.Number:
      return SoQLType.SoQLNumberT;
    case CatalogDataType.CalendarDate:
      return SoQLType.SoQLFloatingTimestampT;
    case CatalogDataType.Date:
      return SoQLType.SoQLFixedTimestampT;
    case CatalogDataType.Checkbox:
      return SoQLType.SoQLBooleanT;
    case CatalogDataType.URL:
      return SoQLType.SoQLURLT;
    case CatalogDataType.Point:
      return SoQLType.SoQLPointT;
    case CatalogDataType.MultiLine:
      return SoQLType.SoQLMultiLineT;
    case CatalogDataType.MultiPolygon:
      return SoQLType.SoQLMultiPolygonT;
    case CatalogDataType.Polygon:
      return SoQLType.SoQLPolygonT;
    case CatalogDataType.Line:
      return SoQLType.SoQLLineT;
    case CatalogDataType.MultiPoint:
      return SoQLType.SoQLMultiPointT;
    case CatalogDataType.json:
      return SoQLType.SoQLJsonT;
    // TODO: this is not all the types!! but SoQLType doesn't have all the types either
  }
  throw new Error(`Accessing a catalog data type that has not been implemented: ${catalogDataType}`);
};

export const soqlTypeToCatalogDataType = (soqlType: SoQLType): CatalogDataType => {
  switch (soqlType) {
    case SoQLType.SoQLTextT:
      return CatalogDataType.Text;
    case SoQLType.SoQLNumberT:
      return CatalogDataType.Number;
    case SoQLType.SoQLFloatingTimestampT:
      return CatalogDataType.CalendarDate;
    case SoQLType.SoQLFixedTimestampT:
      return CatalogDataType.Date;
    case SoQLType.SoQLBooleanT:
      return CatalogDataType.Checkbox;
    case SoQLType.SoQLURLT:
      return CatalogDataType.URL;
    case SoQLType.SoQLPointT:
      return CatalogDataType.Point;
    case SoQLType.SoQLMultiLineT:
      return CatalogDataType.MultiLine;
    case SoQLType.SoQLMultiPolygonT:
      return CatalogDataType.MultiPolygon;
    case SoQLType.SoQLPolygonT:
      return CatalogDataType.Polygon;
    case SoQLType.SoQLLineT:
      return CatalogDataType.Line;
    case SoQLType.SoQLMultiPointT:
      return CatalogDataType.MultiPoint;
    case SoQLType.SoQLJsonT:
      return CatalogDataType.json;
    // TODO: this is not all the types!! but SoQLType doesn't have all the types either
  }
  throw new Error(`Accessing a catalog data type that has not been implemented: ${soqlType}`);
};
