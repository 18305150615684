import React, { createContext, useEffect, useState } from 'react';
import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

// @ts-expect-error TS(2307) FIXME: Cannot find module './video-sources.yml' or its co... Remove this comment to see the full error message
import videoSources from './video-sources.yml';

export interface ITopic {
  key: string;
  label: string;
}

export interface IVideoSource {
  title: string;
  description: string;
  topic: string;
  icon: {
    name: string;
    type: string;
  };
  audience: string;
  permission: string;
  length: string;
  wistia: {
    embedid: string
  };
}

export interface IVideoSources {
  [key: string]: IVideoSource[];
}

export interface IVideoSourcesContext {
  filterByAudience: boolean;
  setFilterByAudience: (filterByAudience: boolean) => void;
  filterByTopic: string[];
  setFilterByTopic: (filterByTopic: string[]) => void;
  videoSourcesByKey: IVideoSources;
  availableTopics: ITopic[];
  selectedTopics: string[];
  setSelectedTopics: (selectedTopics: string[]) => void;
}

type Props = {
  children: React.ReactNode;
};

export function userHasRole(role: string) {
  const userRights = window.socrata?.currentUser?.rights || [] as string[];

  return userRights.includes(role);
}

function getFilteredSources(filterByAudience: boolean) {
  if (!filterByAudience) {
    return videoSources;
  }

  const isEDP = FeatureFlags.value('strict_permissions') || false;
  // logged in but unroled user at least has an empty array as rights
  // anonymous users should have socrata.currentUser as undefined
  const isLoggedInUser = window.socrata?.currentUser?.rights instanceof Array;

  return (videoSources as IVideoSource[]).filter((source) => {
    const isAudienceMatch = source.audience === 'all' || source.audience === (isEDP ? 'edp' : 'odp');
    const isPermissionMatch = source.permission === 'none'
      || (source.permission === 'unroled' && isLoggedInUser)
      || userHasRole(source.permission);

    return isAudienceMatch && isPermissionMatch;
  });
}

function groupByTopic(sources: IVideoSource[]) {
  return _groupBy(sources, 'topic');
}

function findAndSortTopics(sources: IVideoSources) {
  const scope = 'shared.components.videos.categories';

  return _keys(sources)
    .map((key: string) => ({
      key,
      label: I18n.t(key, { scope })
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

function unselectUnavailableTopics(available: ITopic[], selected: string[]) {
  const availableTopicKeys = available.map(topic => topic.key);
  return selected.filter(topic => availableTopicKeys.indexOf(topic) > -1);
}

const defaultSources = groupByTopic(getFilteredSources(true));
const defaultContext = {
  filterByAudience: true,
  setFilterByAudience: () => {},
  filterByTopic: [],
  setFilterByTopic: () => {},
  videoSourcesByKey: defaultSources,
  availableTopics: findAndSortTopics(defaultSources),
  selectedTopics: [],
  setSelectedTopics: () => {},
};
export const VideoSourcesContext = createContext<IVideoSourcesContext>(defaultContext);

function VideoSourcesProvider(props: Props) {
  const { children } = props;

  const [filterByAudience, setFilterByAudience] = useState<boolean>(defaultContext.filterByAudience);
  const [filterByTopic, setFilterByTopic] = useState<string[]>(defaultContext.filterByTopic);
  const [videoSourcesByKey, setVideoSourcesByKey] = useState<IVideoSources>(defaultContext.videoSourcesByKey);
  const [availableTopics, setAvailableTopics] = useState<ITopic[]>(defaultContext.availableTopics);
  const [selectedTopics, setSelectedTopics] = useState<string[]>(defaultContext.selectedTopics);

  useEffect(() => {
    const updatedSources = groupByTopic(getFilteredSources(filterByAudience));
    const updatedAvailableTopics = findAndSortTopics(updatedSources);

    setVideoSourcesByKey(updatedSources);
    setAvailableTopics(updatedAvailableTopics);
    setSelectedTopics(unselectUnavailableTopics(updatedAvailableTopics, selectedTopics));
  }, [filterByAudience]);

  const store = {
    filterByAudience,
    setFilterByAudience,
    filterByTopic,
    setFilterByTopic,
    videoSourcesByKey,
    availableTopics,
    selectedTopics,
    setSelectedTopics,
  };

  return (
    <VideoSourcesContext.Provider value={store}>
      {children}
    </VideoSourcesContext.Provider>
  );
}

export default VideoSourcesProvider;
