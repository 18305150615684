import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import I18n from 'common/i18n';
import PreferenceTableCheckBox from 'common/notifications/components/AlertSettingModal/PreferenceTableCheckBox';
import { OnAlertNotificationChange } from 'common/notifications/components/AlertSettingModal/PreferenceTable';
import { MatchParam } from 'common/notifications/components/AlertSettingModal/PreferenceSection';
import { Input } from 'common/components/Forms';
import { none, Option, some } from 'ts-option';
const scope = 'shared.site_chrome.notifications.alert_setting_modal';

interface Props<T> {
  description: string;
  descriptionTextId: string;
  onAlertNotificationChange: OnAlertNotificationChange;
  preferenceKey: string;
  preferences: any;
  matchParamSpec?: MatchParam<T>[];
}

interface MatchParamProps<T> {
  spec: MatchParam<T>;
  value: T;
  onChangeMatchParam: (value: any) => void;
}

function MatchParameter<T>({ spec, value, onChangeMatchParam }: MatchParamProps<T>) {
  const [error, setError] = useState(none as Option<JSX.Element>);
  const [currentValue, setValue] = useState(spec.intoString(value));

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    setValue(input);
    const result = spec.validate(input);
    if (result.type === 'error') {
      setError(some(<span>{result.message}</span>));
      onChangeMatchParam(null);
    } else {
      setError(none);
      onChangeMatchParam(result.value);
    }
  };

  return (
    <div className="match-param">
      <Input
        // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'string'.
        errorMessage={error.getOrElseValue(<div></div>)}
        required={spec.required}
        valid={error.isEmpty}
        label={spec.name}
        value={currentValue}
        onChange={onChange}
        placeholder={spec.placeholder || ''}
      />
    </div>
  );
}

function PreferenceTableRowWithCheckBox<T>({
  description,
  descriptionTextId,
  onAlertNotificationChange,
  preferenceKey,
  preferences,
  matchParamSpec
}: Props<T>) {
  const productTitle = I18n.t('table_header.product_alerts', { scope });
  const productPreferenceKey = 'enable_product_notification';
  const emailPreferenceKey = 'enable_email_notification';
  const emailTitle = I18n.t('table_header.email_notifications', { scope });
  const preferenceHash = _.get(preferences, preferenceKey, {
    name: preferenceKey,
    enable_product_notification: false,
    enable_email_notification: false,
    match_params: (matchParamSpec || []).reduce((acc, paramSpec) => ({...acc, [paramSpec.key]: paramSpec.default }), {})
  });

  const eitherChecked = preferenceHash[emailPreferenceKey] || preferenceHash[productPreferenceKey];
  // For screenreaders, make sure there's a period at the end of the description. Otherwise,
  // the group checkbox labels (which include all row descriptions) get read together as one
  // giant run-on sentence.
  let ariaLabel = description;
  if (ariaLabel[ariaLabel.length - 1] !== '.') {
    ariaLabel = ariaLabel + '.';
  }
  let params = null;
  if (eitherChecked && matchParamSpec) {
    params = (
      <>
        <div className="break"></div>
        <div className="match-params">
          {matchParamSpec.map((spec, i) => {
            const value = _.get(preferenceHash, `match_params.${spec.key}`);
            /* eslint @typescript-eslint/no-shadow: "warn" */
            const onChangeMatchParam = (value: T) => {
              onAlertNotificationChange(preferenceKey, {
                ...preferenceHash,
                match_params: {
                  ...(preferenceHash.match_params || {}),
                  [spec.key]: value
                }
              });
            };
            return (
              <MatchParameter
                spec={spec}
                value={_.isUndefined(value) ? spec.default : value}
                key={spec.key}
                onChangeMatchParam={onChangeMatchParam}
              />
            );
          })}
        </div>
      </>
    );
  }

  const toggleCheckbox = (keyName: string) => () => {
    onAlertNotificationChange(preferenceKey, {
      ...preferenceHash,
      [keyName]: !preferenceHash[keyName]
    });
  };

  return (
    <div className={classNames(preferenceKey, 'alert-preference-option-row', 'row-disambiguation')}>
      <div className="alert-preference-checkbox-description" aria-label={ariaLabel} id={descriptionTextId}>
        {description}
      </div>
      <div className="alert-preference-options">
        {/* It's important that all checkboxes have stable IDs, since otherwise focus goes haywire on render. */}
        <PreferenceTableCheckBox
          id={`alert-preference-checkbox-${preferenceKey}-${productPreferenceKey}`}
          toggleCheckbox={toggleCheckbox(productPreferenceKey)}
          checked={preferenceHash[productPreferenceKey] || false}
          description={description}
          preferenceName={preferenceKey}
          preferenceType={productPreferenceKey}
          preferenceTypeLabel={productTitle}
        />
        <PreferenceTableCheckBox
          id={`alert-preference-checkbox-${preferenceKey}-${emailPreferenceKey}`}
          toggleCheckbox={toggleCheckbox(emailPreferenceKey)}
          checked={preferenceHash[emailPreferenceKey] || false}
          description={description}
          preferenceName={preferenceKey}
          preferenceType={emailPreferenceKey}
          preferenceTypeLabel={emailTitle}
        />
      </div>
      {params}
    </div>
  );
}

export default PreferenceTableRowWithCheckBox;
