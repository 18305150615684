import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import jstz from 'jstz';

import _getLocale from 'common/js_utils/getLocale';

/**
 * This uses Unicode tokens (https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table)
 * for date formats
 */
export const COMMON_DATE_FORMATS = ['MM/dd/yyyy', 'M/dd/yyyy', 'M/d/yyyy', 'MM/dd/yy', 'M/dd/yy', 'M/d/yy'];

export const DATE_FORMAT = 'YYYY-MM-DD';

export enum FILTER_TYPES {
  RANGE = 'Range',
  RELATIVE = 'Relative'
}

export const RELATIVE_FILTERS = {
  CUSTOM: 'custom',
  DATE_TO_TODAY: 'date_to_today',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  THIS_MONTH: 'this_month',
  THIS_QUARTER: 'this_quarter',
  THIS_WEEK: 'this_week',
  THIS_YEAR: 'this_year',
  THIS_CALENDAR_YEAR: 'this_calendar_year',
  THIS_FISCAL_YEAR: 'this_fiscal_year',
  TODAY: 'today',
  YESTERDAY: 'yesterday'
};

export type RELATIVE_FILTER_TYPES =
  | typeof RELATIVE_FILTERS.CUSTOM
  | typeof RELATIVE_FILTERS.DATE_TO_TODAY
  | typeof RELATIVE_FILTERS.LAST_WEEK
  | typeof RELATIVE_FILTERS.LAST_MONTH
  | typeof RELATIVE_FILTERS.THIS_QUARTER
  | typeof RELATIVE_FILTERS.THIS_WEEK
  | typeof RELATIVE_FILTERS.THIS_YEAR
  | typeof RELATIVE_FILTERS.THIS_CALENDAR_YEAR
  | typeof RELATIVE_FILTERS.THIS_FISCAL_YEAR
  | typeof RELATIVE_FILTERS.YESTERDAY
  | typeof RELATIVE_FILTERS.TODAY;

export const RELATIVE_FILTER_VALUES = {
  [RELATIVE_FILTERS.CUSTOM]: { period: 'day', value: 10, type: RELATIVE_FILTERS.CUSTOM },
  [RELATIVE_FILTERS.DATE_TO_TODAY]: { period: 'day', value: 0, type: RELATIVE_FILTERS.DATE_TO_TODAY },
  [RELATIVE_FILTERS.LAST_WEEK]: { period: 'day', value: 7, type: RELATIVE_FILTERS.LAST_WEEK },
  [RELATIVE_FILTERS.LAST_MONTH]: { period: 'day', value: 30, type: RELATIVE_FILTERS.LAST_MONTH },
  [RELATIVE_FILTERS.THIS_MONTH]: { period: 'month', value: 0, type: RELATIVE_FILTERS.THIS_MONTH },
  [RELATIVE_FILTERS.THIS_QUARTER]: { period: 'quarter', value: 0, type: RELATIVE_FILTERS.THIS_QUARTER },
  [RELATIVE_FILTERS.THIS_WEEK]: { period: 'week', value: 0, type: RELATIVE_FILTERS.THIS_WEEK },
  [RELATIVE_FILTERS.THIS_YEAR]: { period: 'year', value: 0, type: RELATIVE_FILTERS.THIS_YEAR },
  [RELATIVE_FILTERS.THIS_CALENDAR_YEAR]: {
    period: 'year',
    value: 0,
    type: RELATIVE_FILTERS.THIS_CALENDAR_YEAR
  },
  [RELATIVE_FILTERS.THIS_FISCAL_YEAR]: {
    period: 'fiscal_year',
    value: 0,
    type: RELATIVE_FILTERS.THIS_FISCAL_YEAR
  },
  [RELATIVE_FILTERS.TODAY]: { period: 'day', value: 0, type: RELATIVE_FILTERS.TODAY },
  [RELATIVE_FILTERS.YESTERDAY]: { period: 'day', value: 1, type: RELATIVE_FILTERS.YESTERDAY }
};

interface SINGLE_SELECT_BY_PROPS {
  DAY: 'day';
  MONTH: 'month';
  YEAR: 'year';
  FISCAL_YEAR: 'fiscal_year';
}

export const SINGLE_SELECT_BY: SINGLE_SELECT_BY_PROPS = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  FISCAL_YEAR: 'fiscal_year'
};

type SINGLE_SELECT_BY_KEYS = keyof typeof SINGLE_SELECT_BY;
export type SINGLE_SELECT_BY_VALUES = typeof SINGLE_SELECT_BY[SINGLE_SELECT_BY_KEYS]; //

// Formats an ISO8601 date to something pretty like January 1, 1970.
export function formatDate(date: moment.MomentInput, format: string, fallback?: any) {
  const dateFormat = _.defaultTo(format, 'MMMM D, YYYY');
  const momentDate = moment(date, moment.ISO_8601);
  return momentDate.isValid() ? momentDate.format(dateFormat) : fallback;
}

export function momentDateFormatToDatePickerDateFormat(dateFormat: string) {
  return dateFormat.replace(/[YDA]/g, (match: string) => match.toLowerCase());
}

export function formatToSoqlDate(date: string | undefined) {
  // TODO: Verify if date should be a moment or it should be converted to one here
  // @ts-expect-error
  return date.format('YYYY-MM-DDTHH:mm:ss');
}

export function getTodayDate() {
  return moment().startOf('day').format(DATE_FORMAT);
}

export function getYesterdayDate() {
  return moment().subtract(1, 'day').startOf('day').format(DATE_FORMAT);
}

export function formatToInclusiveSoqlDateRange(value: { start: any; end: any }, options?: any) {
  let { start, end } = value;

  start = moment(start).startOf('day');
  end = moment(end).endOf('day');

  if (options?.asUTC) {
    start = start.utc();
    end = end.utc();
  }

  start = formatToSoqlDate(start);
  end = formatToSoqlDate(end);

  return { start, end };
}

// See also platform-ui/frontend/public/javascripts/screens/all-screens.js
// See also platform-ui/frontend/public/javascripts/screens/site-appearance.js
// See also platform-ui/common/js_utils/getLocale.js
const getLocale = () => _getLocale(window);

export const getTimezone = (): string =>
  // @ts-ignore
  _.get(window?.blist, 'configuration.userTimeZoneName', jstz.determine().name());

// Formats an ISO8601 date to something pretty like January 1, 1970.
// If there are any changes made to the formatting logic below, make corresponding changes to:
// frontend/public/javascripts/common/locale.js:dateLocalize()
// And frontend/public/javascripts/screens/all-screens.js
// On or about ~L230:$('.dateLocalize').each(function() { ...
export function formatDateWithLocale(date: moment.MomentInput, withTime = false) {
  const locale = getLocale();

  let dateFormat;
  if (locale === 'en') {
    if (withTime) {
      dateFormat = 'MMMM D, YYYY h:mm A z';
    } else {
      dateFormat = 'MMMM D, YYYY';
    }
  } else {
    if (withTime) {
      dateFormat = 'MMMM D, YYYY H:mm z';
    } else {
      // not sure about this, since moment *should* translate month/date names
      // when you call .locale() and then format()?
      dateFormat = 'LL';
    }
  }

  return (
    momentTimezone(date, momentTimezone.ISO_8601)
      // @ts-ignore
      .tz(getTimezone())
      .locale(locale)
      .format(dateFormat)
  );
}

// Where rawdate is seconds-since-epoch (how core returns
// timestamps to us)
export function formatRawDateAsISO8601(rawdate: number) {
  // @ts-ignore
  return (
    moment(rawdate * 1000)
      // @ts-ignore
      .tz(getTimezone())
      // @ts-ignore
      .toISOString()
  );
}

/**
 * @param  {?} month
 * @return {type} whether month is a number representing a valid, 0 indexed month
 */
export function isValidZeroIndexedMonth(month: number) {
  return !_.isNaN(month) && month >= 0 && month <= 11;
}

/**
 * @param {string} firstQuarterStartMonth 0 indexed
 * @return {Array[Number]} array of 0 indexed quarter start months
 */
export function getAllQuarterStartMonths(firstQuarterStartMonth: number) {
  if (!isValidZeroIndexedMonth(firstQuarterStartMonth)) {
    return [];
  }

  return _.times(4, (i) => (firstQuarterStartMonth + 3 * i) % 12);
}

/**
 * Returns the beginning of the quarter that month is in
 * @param  {Number} month 0 indexed. the month for which to find the first month
 *                  of its quarter
 * @param  {Number} firstQuarterStartMonth 0 indexed number representing the
 *                  first month in the first quarter. defaults to January
 * @return {Number} 0 indexed number representing the first number in the
 *                  quarter that contains `month`
 */
export function getStartOfQuarterMonth(month: number, firstQuarterStartMonth = 0) {
  if (!isValidZeroIndexedMonth(month) || !isValidZeroIndexedMonth(firstQuarterStartMonth)) {
    return -1;
  }
  const quarterMonths = getAllQuarterStartMonths(firstQuarterStartMonth).sort((a, b) => a - b); // javascript's default sort is alphabetical
  const delta = month - quarterMonths[0];
  const quarterIndex = delta < 0 ? 3 : Math.floor(delta / 3);
  return quarterMonths[quarterIndex];
}

export function getQuarterNumber(month: number, firstQuarterStartMonth = 0) {
  if (!isValidZeroIndexedMonth(month) || !isValidZeroIndexedMonth(firstQuarterStartMonth)) {
    return -1;
  }
  const startMonths = getAllQuarterStartMonths(firstQuarterStartMonth).map((startMonth) =>
    _.times(3, (i) => (startMonth + i) % 12)
  );

  for (let i = 0; i < startMonths.length; i++) {
    if (startMonths[i].indexOf(month) >= 0) {
      return i + 1;
    }
  }
}

export function shouldOverrideFiscalYear(): boolean {
  // This will allow fiscal years to fail safely if they are disabled after being used.
  const fiscalYearConfig = _.get(window, 'socrata.fiscalYearConfig', {});
  return !fiscalYearConfig.fy_filters_enabled || !_.isNumber(fiscalYearConfig.fy_month);
}

export function getFiscalYearStartMoment() {
  const fiscalYearConfig = _.get(window, 'socrata.fiscalYearConfig');
  const fiscalYearMonth = shouldOverrideFiscalYear() ? 0 : fiscalYearConfig.fy_month;
  // Will always give 1st of the month, and default to Jan if month is invalid.
  return moment().date(1).month(fiscalYearMonth);
}

/**
 * Returns the calendar year in which the fiscal year falls.
 *
 * @param {string | undefined} containedDate String representation of a date that moment can parse
 * @returns number
 */
export function getFiscalEndYear(containedDate: string | undefined): number {
  const selectedYear = moment(containedDate).year();
  // Special case for FY starting in January, which is really the same as the previous calendar year.
  // Its a bit nonsensical, but we'll handle it. We add one in that case to get the correct year.
  return getFiscalYearStartMoment().month() === 0 ? selectedYear + 1 : selectedYear;
}

/**
 * Return the start and stop dates for a calendar year containing the given date
 *
 * @param {moment.Moment} containedDate
 * @returns
 */
export function getSelectByYearStartAndEndDates(containedDate: moment.Moment) {
  const startMoment = containedDate.month(0).date(1);
  return [startMoment.format(DATE_FORMAT), startMoment.add(1, 'year').subtract(1, 'day').format(DATE_FORMAT)];
}

/**
 * Return the start and stop dates for a fiscal year containing the given date
 *
 * We use `end` and subtract 1 from the year so that the start and end are set correctly
 * whether we just came from calendar years or are already in fiscal years.
 * eg. Calendar year 2019-01-01 to 2019-12-31, becomes 2019 - 1 = 2018-09-01 to 2019-08-31
 *
 * @param {moment.Moment} containedDate
 */
export function getSelectByFiscalYearStartAndEndDates(containedDate: moment.Moment) {
  const endYear = getFiscalEndYear(containedDate.format(DATE_FORMAT));
  const startMoment = getFiscalYearStartMoment().year(endYear - 1);
  return [startMoment.format(DATE_FORMAT), startMoment.add(1, 'year').subtract(1, 'day').format(DATE_FORMAT)];
}
