// Vendor Imports
import _ from 'lodash';

// Project Imports
import I18n from 'common/i18n';

import MetadataProvider, {
  getComputedColumns,
  getDisplayableColumns
} from './dataProviders/MetadataProvider';
//@ts-expect-error
import CategoricalDataManager from './dataProviders/CategoricalDataManager';
//@ts-expect-error
import TimeDataManager from './dataProviders/TimeDataManager';
import * as VifHelpers from './helpers/VifHelpers';
//@ts-expect-error
import { getColumnFormats } from './helpers/ColumnFormattingHelpers';
import {
  shouldRenderDrillDown,
  getGroupByColumnName,
  isGrouping,
  isOneHundredStacked,
  isPieVisualization,
  isScatterVisualization
} from './helpers/VifSelectors';

import { InlineDataRows, Vif, VifMeasure, DataValue } from './vif';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { SoQLType } from 'common/types/soql';
import { Filters } from 'common/components/FilterBar/types';
import { FeatureFlags } from 'common/feature_flags';

// Constants
import { DIMENSION_INDEX, SERIES_TYPE_FLYOUT } from './views/SvgConstants';
import { ClientContextVariable } from 'common/types/clientContextVariable';

interface GetVisualizationDataOptions {
  categoricalOnly?: boolean;
  newVif: Vif;
}

interface Data {
  /** Often empty */
  columns: string[];
  columnFormats: {
    [fieldName: string]: ColumnFormat;
  };
  rows: InlineDataRows;
}

interface VisualizationData {
  newColumns: ViewColumn[];
  newComputedColumns: ViewColumn[];
  newData: Data;
  newFlyoutData: Data;
  newVif: Vif;
  newTableVif: Vif;
}

export async function getVisualizationData({
  categoricalOnly,
  newVif
}: GetVisualizationDataOptions): Promise<VisualizationData> {
  const otherLabel = I18n.t('shared.visualizations.charts.common.other_category');
  const domain = _.get(newVif, 'series[0].dataSource.domain');
  const datasetUid = _.get(newVif, 'series[0].dataSource.datasetUid');
  const precision = _.get(newVif, 'series[0].dataSource.precision');

  let dimensionColumnName = _.get(newVif, 'series[0].dataSource.dimension.columnName');
  const currentDrilldownDimensionColumnName = _.get(
    newVif,
    'series[0].dataSource.dimension.currentDrilldownColumnName'
  );

  if (shouldRenderDrillDown(newVif) && currentDrilldownDimensionColumnName) {
    dimensionColumnName = currentDrilldownDimensionColumnName;
  }

  // Get the data provider
  const datasetMetadataProvider = new MetadataProvider({ domain, datasetUid }, true);
  const datasetMetadata = await datasetMetadataProvider.getDatasetMetadata();
  const dimension = _.find(datasetMetadata.columns, (column) => dimensionColumnName === column.fieldName);
  if (!dimension) throw new Error('could not find column in dataset metadata');

  const useTimeDataManager =
    !categoricalOnly &&
    !_.isUndefined(dimension) &&
    dimension.dataTypeName === SoQLType.SoQLFloatingTimestampT &&
    precision !== 'none';
  const getData = useTimeDataManager ? TimeDataManager.getData : CategoricalDataManager.getData;

  // Fetch data
  const seriesVif = _.cloneDeep(newVif);
  seriesVif.series = _.reject(seriesVif.series, (series) => series.type === SERIES_TYPE_FLYOUT);

  const [newColumns, newData] = await Promise.all([
    datasetMetadataProvider.getDisplayableFilterableColumns({ shouldGetColumnStats: false }),
    getData(seriesVif)
  ]);

  // Fetch flyout data
  let flyoutVif = _.cloneDeep(newVif);
  flyoutVif.series = _.filter(flyoutVif.series, (series) => series.type === SERIES_TYPE_FLYOUT);

  const dimensions = _.chain(newData.rows)
    .map((row) => row[DIMENSION_INDEX])
    /* eslint @typescript-eslint/no-shadow: "warn" */
    .reject((dimensionItem) => dimensionItem === otherLabel)
    .value();

  flyoutVif = VifHelpers.appendDimensionFilters(flyoutVif, dimensions, newColumns);

  const newFlyoutData = await getData(flyoutVif);
  // Append column formats
  const displayableColumns = getDisplayableColumns(datasetMetadata);

  let newComputedColumns;
  if (FeatureFlags.value('computed_region_system_columns')) {
    newComputedColumns = await datasetMetadataProvider.getFormattedComputedRegionColumns();
  } else {
    newComputedColumns = getComputedColumns(datasetMetadata);
  }

  const columnFormats = getColumnFormats(displayableColumns);

  newData.columnFormats = columnFormats;
  newFlyoutData.columnFormats = columnFormats;
  const newTableVif = generateSummaryTableVif(newVif, newColumns, newData, dimension, newFlyoutData);
  return { newColumns, newComputedColumns, newData, newFlyoutData, newVif, newTableVif };
}

// This function generates a vif using the data that's already been fetched, and passing it down to subsequent rendering
export function generateSummaryTableVif(
  newVif: Vif,
  newColumns: ViewColumn[],
  newData: Data,
  dimensionColumn: ViewColumn,
  newFlyoutData: Data
): Vif {
  let summaryTableColumns = [];
  let data = _.cloneDeep(newData.rows);
  const flyoutData = newFlyoutData ? _.cloneDeep(newFlyoutData.rows) : null;

  // First set the dimension column as the first column
  if (!isScatterVisualization(newVif)) {
    const dimensionCol = _.cloneDeep(dimensionColumn);
    // Columns sometimes have a `position` set (which allows us to store any re-ordering that may happen
    // in grid view). In the context of the summary table, we just want the columns to appear in the array order.
    _.unset(dimensionCol, 'position');
    summaryTableColumns.push(dimensionCol);
  }

  // Set the row data depending on the grouping style
  if (isOneHundredStacked(newVif)) {
    data = insertPercentageOfRow(data);
  }

  if (isPieVisualization(newVif)) {
    data = insertPercentageOfColumn(data as [any, number]);
  }

  if (isGrouping(newVif)) {
    data = getGroupedByRowData(newData, isOneHundredStacked(newVif));

    // After the data is set here, we need to add the grouping column as the second column
    const groupByColumnName = getGroupByColumnName(newVif);
    const groupByColumn = _.find(
      newColumns,
      (column) => groupByColumnName === column.fieldName
    ) as ViewColumn;
    _.unset(groupByColumn, 'position');
    summaryTableColumns.push(groupByColumn);
  }

  // Loop through the measures and create the columns
  _.each(newVif.series, (series) => {
    const measure = _.get(series, 'dataSource.measure');
    const units = formatUnitsAsString(series?.unit || {});
    const measureColumn = getMeasureColumn(measure, newColumns, units);
    if (!measureColumn) {
      return;
    }

    // Columns sometimes have a `position` set (which allows us to store any re-ordering that may happen
    // in grid view). In the context of the summary table, we just want the columns to appear in the array order.
    _.unset(measureColumn, 'position');
    summaryTableColumns.push(measureColumn);

    // then, if stacked 100% add a percentColumn after each measure column
    if (isOneHundredStacked(newVif) && series.type !== 'flyout') {
      const percentColumn = getPercentColumn(measureColumn);
      summaryTableColumns.push(percentColumn);
    }
  });

  // If it's a pie chart, add a percent column as the third column
  if (isPieVisualization(newVif)) {
    const percentColumn = getPercentColumn();
    // NOTE: We seem to have a polyfill for Array.prototype.splice
    // which makes the behavior unable to insert an element.
    summaryTableColumns = _.concat(
      _.slice(summaryTableColumns, 0, 2),
      [percentColumn],
      _.slice(summaryTableColumns, 2)
    );
  }

  const dimensionUnits = _.get(newVif, 'series[0]unit', {});

  // Lastly, generate all the summary table rows to pass into the vif
  const summaryTableRows = flyoutData ? mergeSummaryTableRowData(data, flyoutData) : data;

  // If the dimension is a calendar date, we need to set the formatString so that the correct date format is displayed
  // We will rely on DataTypeFormatter to do this for us down the line
  const isCalendarDate: boolean =
    _.get(summaryTableColumns[0], 'dataTypeName', null) === SoQLType.SoQLFloatingTimestampT;
  if (isCalendarDate) {
    const dateDisplayFormat = _.get(newData, 'dateDisplayFormat', null);
    _.set(summaryTableColumns[0], 'format.formatString', dateDisplayFormat);
    _.set(summaryTableColumns[0], 'renderTypeName', SoQLType.SoQLFloatingTimestampT);
  }

  return {
    configuration: {
      viewSourceDataLink: false
    },
    format: {
      type: 'visualization_interchange_format',
      version: 3
    },
    series: [
      {
        type: 'table',
        unit: dimensionUnits,
        dataSource: {
          endIndex: summaryTableRows.length,
          totalRowCount: summaryTableRows.length,
          type: 'socrata.inline',
          rows: summaryTableRows,
          view: {
            columns: summaryTableColumns
          }
        }
      }
    ]
  };
}

/**
 * Calendars have multiple dimensions and do not have measures,
 * thus need a different Table Vif Generator.
 */
export function generateCalendarSummaryTableVif(newData: Data, dimensionColumn: ViewColumn[]): Vif {
  const columnNames = _.compact(_.get(newData, 'columns'));

  // grab the column info from the dimensionColumn object
  const columns = columnNames.map((columnName, index): ViewColumn => {
    const column = _.find(dimensionColumn, { fieldName: columnName });
    const clonedColumn = _.cloneDeep(column);
    clonedColumn ? _.set(clonedColumn, 'position', index) : null;
    return clonedColumn as ViewColumn;
  });

  // NewData.rows includes empty values when we do not have and EndDate or Title set
  // for the calendar viz. We can verify the appropriate indices to remove from our row data by
  // checking the first 3 columns to see if any are empty. If they are we shouldn't have any
  // row data so we splice it out.
  const rowData = _.cloneDeep(newData.rows);
  _.map(rowData, (row) => {
    _.forEach([2, 1, 0], (i) => {
      if (_.isEmpty(newData.columns[i])) {
        row.splice(i, 1);
      }
    });
    return row;
  });

  // Since paging messaging uses units, we need to set units when there are no rows
  const units = rowData.length === 0 ? { other: 'Rows' } : {};

  return {
    configuration: {
      viewSourceDataLink: false
    },
    format: {
      type: 'visualization_interchange_format',
      version: 3
    },
    series: [
      {
        type: 'table',
        unit: units,
        dataSource: {
          endIndex: rowData.length,
          totalRowCount: rowData.length,
          type: 'socrata.inline',
          rows: rowData,
          view: {
            columns: columns
          }
        }
      }
    ]
  };
}

/**
 * Get a filtered flat table version of a tabular view. Used for Vizcan data
 * preview  and the map summary table.
 */
export function getFilteredDataTableVif(
  datasetUid: string,
  units: any,
  filters: Filters,
  parameterOverrides?: ClientContextVariable[]
): Vif {
  return {
    format: {
      type: 'visualization_interchange_format',
      version: 3
    },
    configuration: {
      viewSourceDataLink: false
    },
    series: [
      {
        dataSource: {
          datasetUid,
          dimension: {},
          type: 'socrata.soql',
          filters,
          parameterOverrides
        },
        type: 'table',
        unit: units
      }
    ]
  };
}

/**
 * Helper functions for generating summary tables:
 */

// This gets the column name that is displayed in the summary table.
// The name will be 'Count of Rows' if the column is aggregated on 'count'
// Otherwise, the name will be '<columnName> (<aggregationFunction>)'

// For Scatter charts the name will be the columnName as there are no aggregateFunctions
export function getMeasureColumn(
  measure: VifMeasure,
  viewColumns: ViewColumn[],
  units: string
): ViewColumn | undefined {
  if (!measure || !viewColumns) {
    return;
  }

  const scope = 'shared.visualizations.charts.common.summary_table.aggregation';
  const { aggregationFunction, columnName } = measure;
  if (aggregationFunction === 'count') {
    return {
      fieldName: 'count',
      name: _.trim(`${I18n.t('count', { scope })} ${units}`),
      renderTypeName: SoQLType.SoQLNumberT
    } as ViewColumn;
  } else {
    const measureColumn = _.find(viewColumns, { fieldName: columnName }) as ViewColumn;
    const { name } = measureColumn;
    return {
      ...measureColumn,
      name: aggregationFunction
        ? _.trim(`${I18n.t(aggregationFunction, { scope, columnName: name })}  ${units}`)
        : _.trim(`${name} ${units}`),
      position: undefined
    };
  }
}

function getPercentColumn(column?: ViewColumn): ViewColumn {
  const scope = 'shared.visualizations.charts.common.summary_table';
  const percentColumnName = I18n.t('percent_column_name', { scope });
  const stackedColumnName = `${column?.name} - ${percentColumnName}`;
  const stackedFieldName = `${column?.fieldName}_percent_of_total`;

  const percentColumn = {
    fieldName: column ? stackedFieldName : 'percent_of_total',
    name: column ? stackedColumnName : percentColumnName,
    renderTypeName: SoQLType.SoQLNumberT,
    format: {
      precisionStyle: 'percentage',
      precision: 0
    }
  } as ViewColumn;

  return percentColumn;
}

function insertPercentageOfColumn(data: [any, number]): InlineDataRows {
  const total = _.sumBy(data, 1 as any); // typescript doesn't have this definition
  const percent = (value: number) => (total !== 0 ? (100 * value) / total : null);

  const rowsWithPercents: InlineDataRows = [];

  _.each(data, (row) => {
    const newRow = [...row, percent(row[1])];
    rowsWithPercents.push(newRow);
  });

  return rowsWithPercents;
}

/**
 * @param {InlineDataRows} originalRows Row data [[dimension, aggregateValue]]
 * @param {InlineDataRows} newRows Row data for additional measures [[dimension, aggregateValue, aggregateValue. ...]]
 *
 * @returns {InlineDataRows} This function merges the two array's of arrays by the value of the first index
 *                           and returns an array of row data with the dimension being at the first index
 *                           and all aggregate values after [[dimension, aggregateValue, ...aggregateValue]]
 */
function mergeSummaryTableRowData(originalRows: InlineDataRows, newRows: InlineDataRows): InlineDataRows {
  const scope = 'shared.visualizations.charts.common.summary_table';
  const newData = _.map(originalRows, (origRow) => {
    const newRowsIndex = _.findIndex(newRows, function (row) {
      return row[0] == origRow[0];
    });

    if (origRow[0] === null && origRow[1] !== null) {
      origRow[0] = I18n.t('no_value', { scope });
    }
    const combinedRows = _.concat(origRow, _.drop(newRows[newRowsIndex]));
    return combinedRows;
  });
  return newData;
}

function insertPercentageOfRow(rows: InlineDataRows): InlineDataRows {
  const newRowDataWithPercents = _.map(rows, (row) => {
    const rowDataWithPercents = [row[0]];
    const rowValues = _.drop(row, 1);
    const total = _.sum(rowValues);
    _.forEach(rowValues, (value: DataValue) => {
      //For each value in this row, insert that value as a percentage of the row total. [ <value> , <percent> ]
      if (typeof value === 'number') {
        const percent = (value / total) * 100;
        rowDataWithPercents.push(value);
        rowDataWithPercents.push(percent);
      }
    });
    return rowDataWithPercents;
  });
  return newRowDataWithPercents;
}

/**
 * This is specific to generating grouped data for Summary Tables.
 * (The group-by calculations for the viz's themselves generates/arranges the data very differently,
 * so is unfortunately not re-usable here.)
 *
 * The goal is that each row represents a unique combination of the dimension values and the grouping values.
 * If the viz is also set to display as 100% stacked, the percentage of each grouping by dimension is added.
 *
 * Sample data example:
 * newData.columns = ["dimension",false,true] (dimension column name, first distinct value in grouping column, second, etc)
 * newData.rows = [["Seattle",9,3],["Tacoma",5,5]]
 *
 * Desired output [stacked]:
 * Seattle, false, 9, [75%]
 * Seattle, true, 3,  [25%]
 * Tacoma, false, 5, [50%]
 * Tacoma, true, 5, [50%]
 */
/* eslint @typescript-eslint/no-shadow: "warn" */
function getGroupedByRowData(newData: Data, isHundredStacked: boolean) {
  const groupByValues = _.drop(newData.columns, 1);

  const newGroupedRowData: InlineDataRows = [];
  _.forEach(newData.rows, (row, i) => {
    const rowValues = _.drop(row, 1);
    const total = _.sum(rowValues);
    _.forEach(groupByValues, (groupValue, j) => {
      newGroupedRowData[i * groupByValues.length + j] = [row[0], groupValue, rowValues[j]];
      if (isHundredStacked) {
        const percent = ((rowValues[j] as number) / total) * 100;
        newGroupedRowData[i * groupByValues.length + j].push(percent);
      }
    });
  });

  return newGroupedRowData;
}

function formatUnitsAsString(dimensionUnits: { one?: string; other?: string }): string {
  let units = '';

  if (_.isEmpty(dimensionUnits) || (_.isEmpty(dimensionUnits.one) && _.isEmpty(dimensionUnits.other))) {
    return units;
  }

  if (dimensionUnits.one && dimensionUnits?.other) {
    units = `(${dimensionUnits.one}/${dimensionUnits.other})`;
  } else {
    units = dimensionUnits.one ? `(${dimensionUnits.one})` : `(${dimensionUnits.other})`;
  }

  return units;
}
