import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';

import type { UsersCatalogSearchResult, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import DomainRights from 'common/types/domainRights';

import { fetchJsonWithParsedError } from 'common/http';
import constants from 'common/utilities/Constants';
import FeatureFlags from 'common/feature_flags';

import {
  userAndTeamAutocompleteUrl,
  getDomain,
  strictPermissionsEnabled
} from 'common/components/AccessManager/Util';
import { CATALOG_SEARCH_DEBOUNCE_MILLISECONDS, USER_TYPES } from 'common/components/AccessManager/Constants';

import {
  PublishedToActionTypes,
  publishedToSearchResultsFetchSuccess,
  publishedToSearchResultsFetchFail,
  publishedToSearchQueryChanged
} from 'common/components/AccessManager/actions/PublishedToActions';

import { getAddedUsers } from './Selectors';

// grab list of (roled) users from the catalog
export function* onPublishedToSearchQueryChanged(action: ReturnType<typeof publishedToSearchQueryChanged>) {
  const { query, domain } = action.payload;

  if (query) {
    // debounce this
    // (we're using takeLatest which will cancel this if it gets called again)
    yield delay(CATALOG_SEARCH_DEBOUNCE_MILLISECONDS);

    const checkRights = strictPermissionsEnabled();
    const filters = pickBy({
      rights: checkRights && [DomainRights.can_collaborate]
    });

    // If this is RDW, we want to make sure that zero results are going to be shown if the user hasn't
    // finished typing in a fully valid email address
    if (FeatureFlags.value('disable_user_autocomplete')) {
      try {
        // by passing the domain, we only receive roled users on that domain
        // this is the desired behavior when searching for users to give permission to
        // Note that users can still be added by email if they are un-roled on the domain
        // (we may want to control this in the future)
        const unmappedResults: UsersCatalogSearchResult = yield call(
          fetchJsonWithParsedError,
          userAndTeamAutocompleteUrl(query, getDomain(), filters)
        );

        // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
        const addedUsers = yield select(getAddedUsers);

        // TODO: EN-21982 - Reassess remapping these results
        const results: UsersCatalogSearchResult = {
          ...unmappedResults,
          results: unmappedResults.results.map((result) =>
            result.team
              ? { ...result, user: { ...result.team, type: USER_TYPES.TEAM } }
              : { ...result, user: { ...result.user, type: USER_TYPES.INTERACTIVE } }
          ) as unknown as UsersCatalogSearchResults[]
        };

        const exactMatchResults: UsersCatalogSearchResult = {
          results: results.results.filter(
            (elem) => elem.user?.email == query || elem.team?.screen_name == query
          )
        };
        yield put(publishedToSearchResultsFetchSuccess(exactMatchResults, addedUsers));
      } catch (error) {
        console.error('Error fetching user search results', error);
        yield put(publishedToSearchResultsFetchFail(error));
      }
    } else {
      try {
        // by passing the domain, we only receive roled users on that domain
        // this is the desired behavior when searching for users to give permission to
        // Note that users can still be added by email if they are un-roled on the domain
        // (we may want to control this in the future)
        const unmappedResults: UsersCatalogSearchResult = yield call(
          fetchJsonWithParsedError,
          userAndTeamAutocompleteUrl(query, domain || getDomain(), filters)
        );

        // TODO: EN-21982 - Reassess remapping these results
        const results = {
          ...unmappedResults,
          results: unmappedResults.results.map((result) =>
            result.team
              ? { ...result, user: { ...result.team, type: USER_TYPES.TEAM } }
              : { ...result, user: { ...result.user, type: USER_TYPES.INTERACTIVE } }
          ) as unknown as UsersCatalogSearchResults[]
        };

        // TODO: Add appropriate return type to generator so yield doesn't return any
        // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
        const addedUsers = yield select(getAddedUsers);

        yield put(publishedToSearchResultsFetchSuccess(results, addedUsers));
      } catch (error) {
        yield put(publishedToSearchResultsFetchFail(error));
      }
    }
  }
}

export default [
  takeLatest(PublishedToActionTypes.PUBLISHED_TO_SEARCH_QUERY_CHANGED, onPublishedToSearchQueryChanged)
];
