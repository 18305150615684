/* Imports */
/* ============================================================================= */
import {
  Status,
  WorkflowTargetAudience
} from 'common/core/approvals_enums';
import {
  GuidanceSummaryV2,
  WorkflowGuidance,
} from 'common/types/approvals';





/* Types & Interfaces */
/* ============================================================================= */

/**
 * The definitions in this file are oriented towards the GuidanceSummaryV2Helper
 * object and its inner workings. As such, they are not likely to be needed by
 * the rest of the codebase.
 *
 * Contrasting this, the definitions in common/types/approvals.ts deal with the
 * data structures of guidance and the data returned from the API specifically.
 * As such, the definitions in that file are more widely needed by the rest of
 * the codebase.
 */

/* Types */
/* ------------------------------------------------------------------- */
export type GuidanceObject = WorkflowGuidance | GuidanceSummaryV2;
export type GuidanceHelper = WorkflowGuidanceHelper | GuidanceSummaryV2Helper;
export type WithGuidanceV2<T> =
  // When passed a WorkflowGuidance object
  T extends WorkflowGuidance ? WorkflowGuidanceHelper :
  // When passed a GuidanceSummaryV2 object
  T extends GuidanceSummaryV2 ? GuidanceSummaryV2Helper :
  // When passed anything else
  never
;

/** The summary object generated from running checks against each workflow in a GuidanceSummaryV2 object */
export type GuidanceCheckSummary<T> = (
  { [key in WorkflowTargetAudience]: T | undefined; } &
  {
    /** The number of workflows which had checks resulting in a non-default value. Named to allow array-like checks */
    length: number;

    /** The total number of workflows checked. See the WORKFLOWS array in
     * `/common/core/approvals/guidanceHelpers/Constants.ts` for the list
     */
    totalWorkflows: number;
  }
);
export type GuidanceCheckSummaryWorkflowValues<T> = Omit<GuidanceCheckSummary<T>, 'length' | 'totalWorkflows'>;

/** The summary object generated from boolean checks against each workflow in the Guidance Summary */
export type GuidanceBooleanCheckSummary = GuidanceCheckSummary<boolean>;

/** Generic check method which can be run against workflows */
export type CheckMethod<T = undefined> = (...args: any[]) => T;
/** Object of generic check methods */
export type CheckMethodsObject<T = undefined> = Record<WorkflowTargetAudience, CheckMethod<T>>;

/** Generic method requiring the WorkflowGuidance */
export type WorkflowGuidanceCheckMethod<T = undefined> = (guidance: WorkflowGuidance, ...args: any[]) => T;
/** Object of methods to check each workflow guidance with */
export type WorkflowGuidanceCheckMethods<T = undefined> = Record<WorkflowTargetAudience, WorkflowGuidanceCheckMethod<T>>;

export type WorkflowGuidanceMethod<T = undefined> = CheckMethod<T> | WorkflowGuidanceCheckMethod<T>;
export type WorkflowGuidanceMethodsObject<T = undefined> = CheckMethodsObject<T> | WorkflowGuidanceCheckMethods<T>;




/* Interfaces */
/* ------------------------------------------------------------------- */
/** Used when approving/rejecting an asset that is in the approvals queue */
export interface UpdateApprovalRequestBody {
  /** New state to set for asset */
  state: Status;

  /** Any notes to add to the asset when approving/rejecting it */
  notes: string;
}

/** The interface helper object for a given workflow's guidance (WorkflowGuidance) */
export interface WorkflowGuidanceHelper {
  /* Methods */
  /* --------------------------------------------------------- */
  /* Async */
  /* ----------------------------------------------- */
  approve: (notes?: string) => Promise<Response>;
  reject: (notes?: string) => Promise<Response>;
  withdraw: () => Promise<Response>;
  submitForApproval: () => Promise<Response>;
  submitChangeAudienceRequest: () => Promise<Response>;
  submitUpdatePublishedAssetRequest: () => Promise<Response>;

  /* Synchronous Boolean Checks */
  /* ----------------------------------------------- */
  willEnterApprovalQueue: () => boolean;
  sitesThatWillBeFederatedToIfMadePublic: () => string[];
  sitesThatWillBeFederatedToIfApprovedToPublic: () => string[];
  canSubmitForApproval: () => boolean;
  canSubmitChangeAudienceRequest: () => boolean;
  canSubmitUpdatePublishedAssetRequest: () => boolean;
  isPending: () => boolean;

  /* Helpers */
  /* ----------------------------------------------- */
  expectedOutcome: () => Status | undefined;
  publishedUid: () => string | undefined;

}

/** The interface helper object for guidance summary (GuidanceSummaryV2)
 * WITHOUT the methods that allow it to mimic a WorkflowGuidance Helper
 */
export interface BaseGuidanceSummaryV2Helper {
  /* Attributes */
  /* --------------------------------------------------------- */
  /** Helper object for the internal guidance if one is included on the Guidance Summary */
  internal: WorkflowGuidanceHelper | undefined;

  /** Helper object for the public guidance if one is included on the Guidance Summary */
  public: WorkflowGuidanceHelper | undefined;

  /** GuidanceBooleanCheckSummary indicating which workflows have guidance */
  workflowsWithGuidance: GuidanceBooleanCheckSummary;


  /* Methods */
  /* --------------------------------------------------------- */

  /* Synchronous Boolean Checks */
  /* ----------------------------------------------- */
  /** Is there a workflow guidance object where `expectedState` equals the given status.
   * (optionally) Does the specified workflow's `expectedState` equal the given status */
  hasExpectedOutcome: (status: Status, workflow?: WorkflowTargetAudience) => boolean;

  /** Is there a workflow guidance object for any workflows or (optionally) a specified workflow */
  hasGuidance: (workflow?: WorkflowTargetAudience) => GuidanceCheckSummary<WorkflowGuidanceHelper> | false;

  /** Is there a workflow guidance object where `currentState` equals the given status.
   * (optionally) Does the specified workflow's `currentState` equal the given status */
  hasStatus: (status: Status, workflow?: WorkflowTargetAudience) => boolean;

  /** Is there a workflow guidance object where `currentState` does NOT equal the given status.
   * (optionally) Does the specified workflow's `currentState` NOT equal the given status */
  hasStatusOtherThan: (status: Status, workflow?: WorkflowTargetAudience) => boolean;

  /** Summarize all the `currentState` values for each of the GuidanceSummaryV2's workflows */
  summarizeAssetStatus: () => GuidanceCheckSummary<Status>;

  /** Summarize whether a change audience request could be submitted on the GuidanceSummaryV2's workflows */
  summarizeCanSubmitChangeAudienceRequest: () => GuidanceBooleanCheckSummary;

  /** Summarize whether the asset could be submitted for approval on the GuidanceSummaryV2's workflows */
  summarizeCanSubmitForApproval: () => GuidanceBooleanCheckSummary;

  /** Summarize whether an update to a published asset request could be submitted on the GuidanceSummaryV2's workflows */
  summarizeCanSubmitUpdatePublishedAssetRequest: () => GuidanceBooleanCheckSummary;

  /** Summarize all the `expectedState` values for each of the GuidanceSummaryV2's workflows */
  summarizeExpectedOutcome: () => GuidanceCheckSummary<Status>;

  /** Summarize any pending approvals on the GuidanceSummaryV2's workflows */
  summarizeIsPending: () => GuidanceBooleanCheckSummary;

  /** Summarize whether an asset would enter the approval queue for any of the GuidanceSummaryV2's workflows */
  summarizeWillEnterApprovalQueue: () => GuidanceBooleanCheckSummary;

  /* Helpers */
  /* ----------------------------------------------- */
  /** Is there a published asset on any of the summary's workflows */
  summarizePublishedUid: () => GuidanceCheckSummary<string>;

  /** Which one (if any) of the summary's workflows has a pending approval */
  whichIsPending: () => WorkflowTargetAudience | undefined;

}

/** The interface helper object for guidance summary (GuidanceSummaryV2)
 * INCLUDING the methods that allow it to mimic a WorkflowGuidance Helper
 */
export interface GuidanceSummaryV2Helper extends BaseGuidanceSummaryV2Helper {
  /* Methods */
  /* --------------------------------------------------------- */
  /* Async */
  /* ----------------------------------------------- */
  approve: (notes?: string) => Promise<Response>;
  reject: (notes?: string) => Promise<Response>;
  withdraw: () => Promise<Response>;
  submitForApproval: (workflow: WorkflowTargetAudience) => Promise<Response>;
  submitChangeAudienceRequest: (workflow: WorkflowTargetAudience) => Promise<Response>;
  submitUpdatePublishedAssetRequest: (workflow: WorkflowTargetAudience) => Promise<Response>;

  /* Synchronous Boolean Checks */
  /* ----------------------------------------------- */
  willEnterApprovalQueue: (workflow?: WorkflowTargetAudience) => boolean;
  sitesThatWillBeFederatedToIfMadePublic: () => string[];
  sitesThatWillBeFederatedToIfApprovedToPublic: () => string[];
  canSubmitForApproval: (workflow?: WorkflowTargetAudience) => boolean;
  canSubmitChangeAudienceRequest: (workflow?: WorkflowTargetAudience) => boolean;
  canSubmitUpdatePublishedAssetRequest: (workflow?: WorkflowTargetAudience) => boolean;
  isPending: (workflow?: WorkflowTargetAudience) => boolean;

  /* Helpers */
  /* ----------------------------------------------- */
  publishedUid: (workflow?: WorkflowTargetAudience) => string | undefined;
}





/* Enums */
/* ------------------------------------------------------------------- */
export enum SubmissionKeyV2 {
  toChangeAudience = 'toChangeAudience',
  toUpdatePublishedAsset = 'toUpdatePublishedAsset',
  toPublishAndChangeAudience = 'toPublishAndChangeAudience'
}
