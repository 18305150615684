import { takeLatest, call, put, select, delay } from 'redux-saga/effects';

import { fetchJsonWithParsedError } from 'common/http';
import {
  filterOwnerSearchResults,
  userAndTeamAutocompleteUrl,
  getDomain
} from 'common/components/AccessManager/Util';
import { CATALOG_SEARCH_DEBOUNCE_MILLISECONDS } from 'common/components/AccessManager/Constants';

import {
  ChangeOwnerActionTypes,
  ownerSearchQueryChanged,
  ownerSearchResultsFetchSuccess,
  ownerSearchResultsFetchFail
} from 'common/components/AccessManager/actions/ChangeOwnerActions';

import { getCurrentOwner } from './Selectors';

// search for ALL users
function* onOwnerSearchQueryChanged(action: ReturnType<typeof ownerSearchQueryChanged>) {
  const { query } = action.payload;

  if (query) {
    // debounce this
    // (we're using takeLatest which will cancel this if it gets called again)
    yield delay(CATALOG_SEARCH_DEBOUNCE_MILLISECONDS);

    try {
      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const results = yield call(fetchJsonWithParsedError, userAndTeamAutocompleteUrl(query, getDomain()));

      // TODO: Add appropriate return type to generator so yield doesn't return any
      // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const currentOwner = yield select(getCurrentOwner);

      // this will only fitler out the current owner, unlike the
      // (much more complicated) filtering done when adding users
      const filteredResults = filterOwnerSearchResults(results, currentOwner);

      yield put(ownerSearchResultsFetchSuccess(filteredResults));
    } catch (error) {
      yield put(ownerSearchResultsFetchFail(error));
    }
  }
}

export default [takeLatest(ChangeOwnerActionTypes.OWNER_SEARCH_QUERY_CHANGED, onOwnerSearchQueryChanged)];
