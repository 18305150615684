import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import pickBy from 'lodash/pickBy';
import { takeLatest, takeEvery, call, put, select, delay } from 'redux-saga/effects';

import type { UsersCatalogSearchResult, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';

import { fetchJsonWithParsedError } from 'common/http';
import { getCurrentUser } from 'common/current_user';
import DomainRights from 'common/types/domainRights';
import constants from 'common/utilities/Constants';
import FeatureFlags from 'common/feature_flags';

import {
  getDomain,
  strictPermissionsEnabled,
  userAndTeamAutocompleteUrl
} from 'common/components/AccessManager/Util';
import {
  CATALOG_SEARCH_DEBOUNCE_MILLISECONDS,
  FEATURE_FLAGS,
  USER_TYPES
} from 'common/components/AccessManager/Constants';
import {
  AddCollaboratorsActionsTypes,
  collaboratorsSearchResultsFetchSuccess,
  collaboratorsSearchResultsFetchFail,
  validatedUserInput,
  collaboratorsSearchQueryChanged,
  validateUserInput
} from 'common/components/AccessManager/actions/AddCollaboratorsActions';

import { getAddedUsers } from './Selectors';

export const unRoledUserCanAddCollaborators = () =>
  !strictPermissionsEnabled() && isEmpty(getCurrentUser()?.rights);

// grab list of (roled) users from the catalog
export function* onCollaboratorsSearchQueryChanged(
  action: ReturnType<typeof collaboratorsSearchQueryChanged>
) {
  const { query } = action.payload;

  if (unRoledUserCanAddCollaborators()) {
    // This allows a collaborator to be added by an un-roled user - that cannot use the autocomplete API
    const emptyResults: UsersCatalogSearchResult = { results: [], resultSetSize: 0 };
    yield put(collaboratorsSearchResultsFetchSuccess(emptyResults, []));
  } else {
    if (query) {
      // debounce this
      // (we're using takeLatest which will cancel this if it gets called again)
      yield delay(CATALOG_SEARCH_DEBOUNCE_MILLISECONDS);

      const checkRights = strictPermissionsEnabled();
      const filters = pickBy({
        rights: checkRights && [DomainRights.can_collaborate]
      });

      if (FeatureFlags.value('disable_user_autocomplete')) {
        try {
          // by passing the domain, we only receive roled users on that domain
          // this is the desired behavior when searching for users to give permission to
          // Note that users can still be added by email if they are un-roled on the domain
          // (we may want to control this in the future)
          const unmappedResults: UsersCatalogSearchResult = yield call(
            fetchJsonWithParsedError,
            userAndTeamAutocompleteUrl(query, getDomain(), filters)
          );

          // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
          const addedUsers = yield select(getAddedUsers);

          // TODO: EN-21982 - Reassess remapping these results
          const results: UsersCatalogSearchResult = {
            ...unmappedResults,
            results: unmappedResults.results.map((result) =>
              result.team
                ? { ...result, user: { ...result.team, type: USER_TYPES.TEAM } }
                : { ...result, user: { ...result.user, type: USER_TYPES.INTERACTIVE } }
            ) as unknown as UsersCatalogSearchResults[]
          };

          const exactMatchResults: UsersCatalogSearchResult = {
            results: results.results.filter(
              (elem) => elem.user?.email == query || elem.team?.screen_name == query
            )
          };
          yield put(collaboratorsSearchResultsFetchSuccess(exactMatchResults, addedUsers));
        } catch (error) {
          console.error('Error fetching user search results', error);
          yield put(collaboratorsSearchResultsFetchFail(error));
        }
      } else {
        try {
          // by passing the domain, we only receive roled users on that domain
          // this is the desired behavior when searching for users to give permission to
          // Note that users can still be added by email if they are un-roled on the domain
          // (we may want to control this in the future)
          const unmappedResults: UsersCatalogSearchResult = yield call(
            fetchJsonWithParsedError,
            userAndTeamAutocompleteUrl(query, getDomain(), filters)
          );
          // TODO: EN-21982 - Reassess remapping these results
          const results: UsersCatalogSearchResult = {
            ...unmappedResults,
            results: unmappedResults.results.map((result) =>
              result.team
                ? { ...result, user: { ...result.team, type: USER_TYPES.TEAM } }
                : { ...result, user: { ...result.user, type: USER_TYPES.INTERACTIVE } }
            ) as unknown as UsersCatalogSearchResults[]
          };

          // TODO: Add appropriate return type to generator so yield doesn't return any
          // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
          const addedUsers = yield select(getAddedUsers);

          yield put(collaboratorsSearchResultsFetchSuccess(results, addedUsers));
        } catch (error) {
          console.error('Error fetching user search results', error);
          yield put(collaboratorsSearchResultsFetchFail(error));
        }
      }
    }
  }
}

export function* onValidateForm(action: ReturnType<typeof validateUserInput>) {
  const { selectedUsers, selectedAccessLevel } = action.payload;

  const validationErrors = [];
  if (isEmpty(selectedUsers)) {
    validationErrors.push({
      translationKey: 'shared.site_chrome.access_manager.manage_collaborators.email_user_error'
    });
  }

  if (isNull(selectedAccessLevel)) {
    validationErrors.push({
      translationKey: 'shared.site_chrome.access_manager.manage_collaborators.role_error'
    });
  }

  yield put(validatedUserInput(validationErrors));
}

export default [
  takeLatest(
    AddCollaboratorsActionsTypes.COLLABORATORS_SEARCH_QUERY_CHANGED,
    onCollaboratorsSearchQueryChanged
  ),
  takeEvery(AddCollaboratorsActionsTypes.VALIDATE_USER_INPUT, onValidateForm)
];
