/**
 * This gets the height of the site chrome header (technically the container).
 * There are a lot of things that effect the size of the site chrome header. The most accurate way to figure out its size
 * is to just look at it. Otherwise you have to take into account if a user is rolled, what ff are on, is there a custom header, etc.
 *
 * @returns The height of the site chrome container
 */
export const getSiteChromeHeight = (): number => {
  const siteChromeContainer = document.getElementById('site-chrome-container');
  if (siteChromeContainer) {
    // The site chrome container will either have a height or a margin, depending on what its wrapping (site-chrome-header or stacked-headers)
    // There is a height when not using a site-chrome-header (unrolled users, custom header),
    // and a bottom margin when using a stacked header (rolled users, though could be forged or not)
    let headerHeight = siteChromeContainer.offsetHeight;
    if (!headerHeight) {
      const style = getComputedStyle(siteChromeContainer);
      headerHeight = parseInt(style.marginBottom);
    }
    return headerHeight;
  } else {
    return 0; // should never happen but here just in case.
  }
};

/**
 * This checks if the site chrome header is fixed
 * NOTE this only checks the immediate children of the site-chrome-container, its not recursive
 *
 * @returns Returns true if the site chrome header is fixed
 */
export const iaSiteChromeHeaderFixed = (): boolean => {
  const siteChromeContainer = document.getElementById('site-chrome-container');
  if (siteChromeContainer) {
    if (siteChromeContainer.style.position === 'fixed') return true;
    // we will check the immediate children for the fixed property
    if (siteChromeContainer.children.length) {
      // @ts-expect-error TS(2488) FIXME: Type 'HTMLCollection' must have a '[Symbol.iterato... Remove this comment to see the full error message
      for (const child of siteChromeContainer.children) {
        if (getComputedStyle(child).position === 'fixed') return true;
      }
    }
  }
  return false;
};

/**
 * This gets the height of the site chrome footer if present.
 * @returns The height of the site chrome footer
 */
export const getSiteChromeFooter = (): number => {
  const siteChromeContainer = document.getElementById('site-chrome-footer');
  if (siteChromeContainer) {
    return siteChromeContainer.offsetHeight;
  } else {
    return 0;
  }
};

/**
 * This gets the height of the asset action bar.
 * There is a lot that goes into when the action bar is there. By just checking if its there,
 * and if it is, getting the size, we're more robust to future changes to its size (or when it shows up)
 *
 * @returns The height of the asset action bar
 */
export const getAssetBarHeight = () => {
  const assetActionBar = document.getElementsByClassName('asset-action-bar');
  if (assetActionBar.length) {
    // should only be one!
    return (assetActionBar[0] as HTMLElement).offsetHeight;
  } else {
    return 0;
  }
};
