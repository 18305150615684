import type JQuery from 'jquery';
import $ from 'jquery';

import _ from 'lodash';
import moment from 'moment';

import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import I18n from 'common/i18n';
import MetadataProvider, { getDisplayableColumns } from './dataProviders/MetadataProvider';
import SoqlDataProvider from './dataProviders/SoqlDataProvider';

// @ts-expect-error
import SoqlHelpers from './dataProviders/SoqlHelpers';
import Calendar from './views/Calendar';
import {
  generateCalendarSummaryTableVif
} from './VisualizationCommon';
import {
  getDefaultDisplayDate,
  getCurrentDisplayDate,
  getDatasetUid,
  getDomain,
  getParameterOverrides,
  getEndDateColumn,
  getEventTitleColumn,
  getStartDateColumn,
  getLockCalendarViewControl
} from 'common/visualizations/helpers/VifSelectors';
// TODO: Convert these files to typescripts
// @ts-expect-error
import { getColumnFormats } from './helpers/ColumnFormattingHelpers';
// @ts-expect-error
import { getDateRangeCondition } from './helpers/CalendarHelper';
// @ts-expect-error
import { getSoqlVifValidator } from './dataProviders/SoqlVifValidator';
import { Vif } from './vif';
import { assertIsNotNil } from 'common/assertions';
import { DateInput } from '@fullcalendar/core/datelib/env';
import { getTodayDate } from 'common/dates';

const START_DATE_ALIAS = '__startDate__';
const END_DATE_ALIAS = '__endDate__';
const EVENT_TITLE_ALIAS = '__event_title__';

type FlyoutEvent = {
  originalEvent?: {
    detail?: Record<string, unknown>
  }
} & JQuery.TriggeredEvent;

type RenderVifEvent = {
  originalEvent?: {
    detail?: Vif
  }
} & JQuery.TriggeredEvent;

export interface SocrataVisualizationFlyoutEvent {
  detail: Record<string, unknown> | null | undefined,
  bubbles?: boolean,
  belowTarget?: boolean,
}

export interface VizError {
  vifValidatorErrors?: string[]
}

// TODO: Figure out how to handle this in typescript
// @ts-expect-error
$.fn.socrataCalendar = function(originalVif: Vif, options: any) {
  originalVif = migrateVif(originalVif);

  const $element: JQuery<any> = $(this);
  const visualization = new Calendar($element, originalVif, options);
  let currentDisplayDate = getCurrentDisplayDate(originalVif);
  const defaultDisplayDate = getDefaultDisplayDate(originalVif);

  const attachEvents = function($currentElement: JQuery<HTMLElement>, currentVisualization: Calendar) {
    $currentElement.one('SOCRATA_VISUALIZATION_DESTROY', function() {
      currentVisualization.destroy();
      detachInteractionEvents();
      detachEvents();
    });

    $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', currentVisualization.invalidateSize);
    $element.on('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  };

  const attachInteractionEvents = function() {
    $element.on('SOCRATA_VISUALIZATION_CALENDAR_FLYOUT', handleFlyout);
  };

  const detachEvents = function() {
    $element.off('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.off('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  };

  const detachInteractionEvents = function() {
    $element.off('SOCRATA_VISUALIZATION_CALENDAR_FLYOUT', handleFlyout);
  };

  const handleFlyout = function(event: JQuery.Event) {
    const currentEvent = event as FlyoutEvent;

    assertIsNotNil(currentEvent?.originalEvent);
    const payload: Record<string, unknown> | null | undefined = currentEvent.originalEvent.detail;
    const socrataVisualizationFlyoutEvent: SocrataVisualizationFlyoutEvent = {
      detail: payload,
      bubbles: true,
      belowTarget: false
    };
    const customEvent = new window.CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', socrataVisualizationFlyoutEvent);

    $element[0].dispatchEvent(customEvent);
  };

  const getData = (vif: Vif, date: DateInput) => {
    const calendarDate = moment.utc(date);
    const domain = getDomain(vif);
    const datasetUid = getDatasetUid(vif);
    const clientContextVariables = getParameterOverrides(vif);
    const titleColumn = getEventTitleColumn(vif);
    const startDateColumn = getStartDateColumn(vif);
    const endDateColumn = getEndDateColumn(vif);
    const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);

    const dateRangeCondition = getDateRangeCondition(calendarDate, startDateColumn, endDateColumn);
    const whereClause =
      whereClauseComponents.length > 0
        ? `WHERE ${whereClauseComponents} AND ${dateRangeCondition}`
        : `WHERE ${dateRangeCondition}`;

    const soqlDataProvider = new SoqlDataProvider({ datasetUid, domain, clientContextVariables });

    const measureColumnNames = _.chain(vif)
      .get('series')
      .map('dataSource.measure.columnName')
      .compact()
      .value();
    const measureSelects = _.map(measureColumnNames, (measureColumnName, index) => {
      return `\`${measureColumnName}\` as ${SoqlHelpers.measureAlias(index)}`;
    });

    let selects = [];
    if (!_.isEmpty(startDateColumn)) {
      selects.push(`\`${startDateColumn}\` as ${START_DATE_ALIAS}`);
    }
    if (!_.isEmpty(endDateColumn)) {
      selects.push(`\`${endDateColumn}\` as ${END_DATE_ALIAS}`);
    }
    if (!_.isEmpty(titleColumn)) {
      selects.push(`\`${titleColumn}\` as ${EVENT_TITLE_ALIAS}`);
    }
    selects = selects.concat(measureSelects);

    const queryString = [`SELECT ${selects.join(',')}`, whereClause].join(' ');

    return soqlDataProvider.rawQuery(queryString).then((resultRows) => {
      const rows = _.map(resultRows, (resultRow) => {
        const title = _.get(resultRow, EVENT_TITLE_ALIAS);
        const startDate = _.get(resultRow, START_DATE_ALIAS);
        const endDate = _.get(resultRow, END_DATE_ALIAS);

        /*
          If additional measures are added through flyouts they will be returned
          in resultRow. Since title, startDate, and endDate (3) are also in resultRow
          we want to exclude these values from vif.series by filtering by the type
          flyout.
          */
        const measureValues = _.map(_.filter(vif.series, { type: 'flyout' }), (seriesItem, index) => {
          return resultRow[SoqlHelpers.measureAlias(index)];
        });

        return [title, startDate, endDate].concat(measureValues);
      });

      return {
        rows,
        columns: [titleColumn, startDateColumn, endDateColumn].concat(measureColumnNames)
      };
    });
  };

  const handleError = (error: VizError) => {
    let messages;

    if (window.console && console.error) {
      console.error(error);
    }

    if (error.vifValidatorErrors) {
      messages = error.vifValidatorErrors;
    } else {
      return visualization.renderGenericError(
        I18n.t('shared.errors.private_or_deleted_asset.message'),
        'privateOrDeletedAsset'
      );
    }

    visualization.renderError(messages);
  };

  const handleRenderVif = (event: JQuery.Event) => {
    const currentEvent = event as RenderVifEvent;
    assertIsNotNil(currentEvent?.originalEvent?.detail);
    const newVif = currentEvent.originalEvent.detail;
    const handleRenderDefaultDisplayDate = getDefaultDisplayDate(newVif);
    const handleRenderCurrentDisplayDate = getCurrentDisplayDate(newVif);
    updateData(migrateVif(newVif), handleRenderDefaultDisplayDate, handleRenderCurrentDisplayDate);
  };

  const updateData = (newVif: Vif,
     updateDataDefaultDisplayDate: DateInput | undefined,
     updateDataCurrentDisplayDate: DateInput | undefined) => {

    const domain = getDomain(newVif);
    const datasetUid = getDatasetUid(newVif);
    const lockCalendarViewControl = getLockCalendarViewControl(newVif);
    const datasetMetadataProvider = new MetadataProvider({ domain, datasetUid }, true);

    // Gets the current date
    let currentLocalDate = updateDataCurrentDisplayDate;
    if (lockCalendarViewControl && (currentLocalDate === null || currentLocalDate === undefined)) {
      currentLocalDate = updateDataDefaultDisplayDate;
    } else if (_.isEmpty(updateDataCurrentDisplayDate)) {
      currentLocalDate = getTodayDate();
    }

    const displayableFilterableColumns = visualization.shouldDisplayFilterBar() ?
      datasetMetadataProvider.getDisplayableFilterableColumns({ shouldGetColumnStats: false }) :
      null;

    visualization.showBusyIndicator();
    detachInteractionEvents();

    $.fn.socrataCalendar.validateVif(newVif).
      then(() => {
        assertIsNotNil(currentLocalDate);
        return Promise.all([
          displayableFilterableColumns,
          datasetMetadataProvider.getDatasetMetadata(),
          getData(newVif, currentLocalDate)
        ]);
      }).
      then(resolutions => {
        const [newColumns, datasetMetadata, newDataWithoutColumnFormats] = resolutions;
        const displayableColumns = getDisplayableColumns(datasetMetadata);
        const newData = { ...newDataWithoutColumnFormats, columnFormats: getColumnFormats(displayableColumns) };
        if (_.isUndefined(currentDisplayDate)) {
          currentDisplayDate = currentLocalDate;
        } else if (currentDisplayDate != getCurrentDisplayDate(newVif)) {
          currentDisplayDate = getCurrentDisplayDate(newVif);
        }
        const renderOptions = { defaultDisplayDate, currentDisplayDate };
        const newTableVif = generateCalendarSummaryTableVif(newData, displayableColumns);
        attachInteractionEvents();
        visualization.render({ newData, newColumns, newVif, renderOptions, newTableVif });
      }).
      catch(handleError).
      finally(() => visualization.hideBusyIndicator());
  };

  updateData(originalVif, defaultDisplayDate, currentDisplayDate);
  attachEvents($element, visualization);
};

/**
 * Checks a VIF for compatibility with this visualization. The intent of this
 * function is to provide feedback while authoring a visualization, not to
 * provide feedback to a developer. As such, messages returned are worded to
 * make sense to a user.
 *
 * Returns a Promise.
 *
 * If the VIF is usable, the promise will resolve.
 * If the VIF is not usable, the promise will reject with an object:
 * {
 *   ok: false,
 *   vifValidatorErrors: Array<String>
 * }
 */

$.fn.socrataCalendar.validateVif = (vif: Vif): Promise<any> => {
  // TODO this will be fixed once we convert getSoqlVifValidator to typescript
  // @ts-expect-error
  return getSoqlVifValidator(vif).then((validator: Validator) => {
    validator.
      requireAtLeastOneSeries().
      toPromise();
  });
};

export default $.fn.socrataCalendar;
