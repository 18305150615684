// Vendor Imports
import _ from 'lodash';
import $ from 'jquery';

// Project Imports
import DistributionChartHelpers from './views/DistributionChartHelpers';
import SvgHistogram from './views/SvgHistogram';
import { generateSummaryTableVif } from './VisualizationCommon';
import SoqlDataProvider from './dataProviders/SoqlDataProvider';
import { getSoqlVifValidator } from './dataProviders/SoqlVifValidator';
import { migrateVif } from './helpers/migrateVif';
import * as ColumnFormattingHelpers from './helpers/ColumnFormattingHelpers';
import SoqlHelpers from './dataProviders/SoqlHelpers';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import MetadataProvider, {
  getComputedColumns,
  getDisplayableColumns
} from 'common/visualizations/dataProviders/MetadataProvider';
import { SoQLType } from 'common/types/soql';
import { FeatureFlags } from 'common/feature_flags';

// Constants
const WINDOW_RESIZE_RERENDER_DELAY = 200;

/**
 * Requests the data required to render the chart, transforms it, and returns a Promise that
 * asynchronously signals success or failure.
 *
 * There are two ways this function will bucket the data: linearly and logarithmically.  Linear
 * buckets all have the same width, and logarithmic buckets have sizes of increasing powers of 10.
 * The bucketing scheme will be automatically determined using a heuristic, but can be overriden by
 * passing in the configuration option "bucketType" in the vif.  The bucketing methods correspond to
 * signed_magnitude_10 and signed_magnitude_linear SoQL functions.
 *
 * On success, the "bucketedData" property will contain the bucketed data, which is an array of buckets.
 * Each bucket has three entries: "start", indicating the lower bound of
 * the bucket's range, "end", indicating the upper bound of the bucket's range, and "value",
 * indicating the value on the y-axis for that bucket.
 *
 * @returns Promise
 */
function getData(vif, seriesIndex) {

  // We're able to automatically detect a bucketType, but it's also possible to explicitly override
  // this. This override is stored in the vif's configuration.
  var bucketTypeOverride = _.get(vif, 'configuration.bucketType');

  // First, fetch the min and max of the column
  return fetchDomain(vif, seriesIndex).

    // Then transform this into an object with information about the bucketType and bucketSize,
    // also passing in an override bucketType from the vif in the event it has been explicitly set.
    then((domain) => DistributionChartHelpers.getBucketingOptions(domain, bucketTypeOverride, vif)).

    // Make the appropriate query to fetch bucketed data once bucketing scheme is known
    then((bucketingOptions) => fetchBucketedData(bucketingOptions, vif, seriesIndex));
}

function dataProvider(vif, seriesIndex) {
  var series = vif.series[seriesIndex];
  return new SoqlDataProvider({
    datasetUid: series.dataSource.datasetUid,
    domain: series.dataSource.domain,
    clientContextVariables: series.dataSource.parameterOverrides
  });
}

/**
 * Using the current data providers and vif, fetches the min and max of the column.  Returns a
 * Promise which will resolve with an object containing "min" and "max" keys.
 * @returns Promise
 */
function fetchDomain(vif, seriesIndex) {
  const column = SoqlHelpers.dimension(vif, seriesIndex);
  let queryParts = [
    `SELECT min(${column}) as \`min\`, max(${column}) as \`max\``
  ];
  const whereClause = SoqlHelpers.whereClauseFilteringOwnColumn(
    vif,
    seriesIndex
  );

  if (whereClause.length > 0) {
    queryParts.push(`WHERE ${whereClause}`);
  }

  const columnNames = ['min', 'max'];
  const columnDomainQuery = `$query=${encodeURIComponent(queryParts.join(' '))}`;

  return dataProvider(vif, seriesIndex). // TODO negative domain.
    getRows(columnNames, columnDomainQuery).
    // Convert the SoqlDataProvider response into an object containing min and max keys.
    then((response) => _.map(_.head(response.rows), parseFloat)).
    then((values) => _.zipObject(columnNames, values));
}

/**
 * Given a set of bucketingOptions, makes the SoQL requests to bucket the data appropriately and
 * returns a Promise containing the results.  The Promise will resolve with an object:
 * {
 *   bucketingOptions: Pass-through of bucketingOptions parameter.
 *   bucketedData: Array of buckets.
 * }
 * @param {Object} bucketingOptions
 * @param {String} bucketingOptions.bucketType - Either "linear" or "logarithmic"
 * @param {Number} bucketingOptions.bucketSize - If bucketType is "linear", the size of each bucket.
 * @returns Promise
 */
function fetchBucketedData(bucketingOptions, vif, seriesIndex) {
  const soqlDataProvider = dataProvider(vif, seriesIndex);

  const whereClauseComponents = SoqlHelpers.whereClauseFilteringOwnColumn(
    vif,
    seriesIndex
  );

  const whereClause = (whereClauseComponents.length > 0) ?
    `WHERE ${whereClauseComponents}` :
    '';

  const queryParameters = {
    column: SoqlHelpers.dimension(vif, seriesIndex),
    columnAlias: '__magnitude__',
    value: SoqlHelpers.aggregationClause(vif, seriesIndex, 'measure'),
    valueAlias: '__value__',
    whereClause
  };

  if (bucketingOptions.bucketType === 'linear') {
    queryParameters.bucketingFunction = `floor(${queryParameters.column}/${bucketingOptions.bucketSize})`;
  } else {
    queryParameters.bucketingFunction = `signed_magnitude_10(${queryParameters.column})`;
  }

  const queryTemplate = [
    'select {bucketingFunction} as {columnAlias}, ',
    '{value} as {valueAlias} ',
    '{whereClause} group by {columnAlias} order by {columnAlias} limit 200'
  ].join('');

  const filteredDataQuery = formatString(queryTemplate, queryParameters);

  return soqlDataProvider.query(
    filteredDataQuery,
    queryParameters.columnAlias,
    queryParameters.valueAlias
  ).then((soqlData) => transformBucketedData(bucketingOptions, soqlData));
}

/**
 * Given an object specifying bucketingOptions and data response,
 * transforms the data into an object with the bucketed data (array of buckets).
 * @param {Object} bucketingOptions
 * @param {Object} response
 * @throws
 * @returns Object
 */
function transformBucketedData(bucketingOptions, response) {
  // Transform the array of arrays into an array of objects, each with 'magnitude' and 'value' keys.
  var data = _.chain(response.rows).
      map((pair) => _.map(pair, parseFloat)).
      map((parsed) => _.zipObject(['magnitude', 'value'], parsed)).
      value();

  var bucketedData = DistributionChartHelpers.bucketData(data, bucketingOptions);

  if (!_.isArray(bucketedData)) {
    throw new Error('Cannot render distribution chart: data is empty');
  }

  return {
    bucketedData: bucketedData,
    bucketingOptions: bucketingOptions
  };
}

$.fn.socrataSvgHistogram = function(originalVif, options) {
  originalVif = migrateVif(originalVif);
  var $element = $(this);
  var visualization = new SvgHistogram($element, originalVif, options);
  var rerenderOnResizeTimeout;

  /**
   * Event handling
   */

  function attachEvents() {

    // Destroy on (only the first) 'SOCRATA_VISUALIZATION_DESTROY' event.
    $element.one('SOCRATA_VISUALIZATION_DESTROY', function() {
      clearTimeout(rerenderOnResizeTimeout);
      visualization.destroy();
      detachEvents();
    });

    $(window).on('resize', handleWindowResize);

    $element.on('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.on('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function detachEvents() {

    $(window).off('resize', handleWindowResize);

    $element.off('SOCRATA_VISUALIZATION_INVALIDATE_SIZE', visualization.invalidateSize);
    $element.off('SOCRATA_VISUALIZATION_RENDER_VIF', handleRenderVif);
  }

  function handleWindowResize() {

    clearTimeout(rerenderOnResizeTimeout);

    rerenderOnResizeTimeout = setTimeout(
      visualization.render(),
      // Add some jitter in order to make sure multiple visualizations are
      // unlikely to all attempt to rerender themselves at the exact same
      // moment.
      WINDOW_RESIZE_RERENDER_DELAY + Math.floor(Math.random() * 10)
    );
  }

  function handleRenderVif(event) {
    var newVif = event.originalEvent.detail;

    updateData(
      migrateVif(newVif)
    );
  }

  function handleError(error) {
    let messages;

    if (window.console && console.error) {
      console.error(error);
    }

    if (error.vifValidatorErrors) {
      messages = error.vifValidatorErrors;
    } else if (error.soqlError) {
      const errorCode = _.get(error, 'soqlError.errorCode');

      messages = errorCode ?
         I18n.t(`shared.visualizations.charts.common.soql_error.${errorCode}`) :
         I18n.t('shared.visualizations.charts.common.error_generic');
    } else {
      return visualization.renderGenericError(
        I18n.t('shared.errors.private_or_deleted_asset.message'),
        'privateOrDeletedAsset'
      );
    }

    if (error.useGenericErrorViz) {
      visualization.renderGenericError(messages, 'histogram');
    } else {
      visualization.renderError(messages);
    }
  }

  function updateData(newVif) {

    $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_START');
    visualization.showBusyIndicator();

    $.fn.socrataSvgHistogram.validateVif(newVif).then(() => {
      const datasetMetadataProvider = new MetadataProvider({
        domain: _.get(newVif, 'series[0].dataSource.domain'),
        datasetUid: _.get(newVif, 'series[0].dataSource.datasetUid')
      }, true);

      const dataRequests = newVif.
        series.
        map(
          function(series, seriesIndex) {

            switch (series.dataSource.type) {

              case 'socrata.soql':
                return makeSocrataDataRequest(newVif, seriesIndex);

              default:
                return Promise.reject(
                  `Invalid/unsupported series dataSource.type: "${series.dataSource.type}".`
                );
            }
          }
        );

      const displayableFilterableColumns = visualization.shouldDisplayFilterBar() ?
        datasetMetadataProvider.getDisplayableFilterableColumns({ shouldGetColumnStats: false }) :
        null;

      const getFormattedComputedRegionColumnsPromise = FeatureFlags.value('computed_region_system_columns') ?
        datasetMetadataProvider.getFormattedComputedRegionColumns() :
        null;

      return Promise.
        all([
          displayableFilterableColumns,
          datasetMetadataProvider.getDatasetMetadata(),
          getFormattedComputedRegionColumnsPromise,
          ...dataRequests
        ]).
        then(
          function(resolutions) {
            const [newColumns, datasetMetadata, newComputedColumns, ...dataResponses] = resolutions;

            let returnNewComputedColumns;
            if (FeatureFlags.value('computed_region_system_columns')) {
              returnNewComputedColumns = newComputedColumns;
            } else {
              returnNewComputedColumns = getComputedColumns(datasetMetadata);
            }

            const displayableColumns = getDisplayableColumns(datasetMetadata);
            dataResponses[0].columnFormats = ColumnFormattingHelpers.getColumnFormats(displayableColumns);


            const newTableVif = generateHistorgramSummaryTableVif(newVif, datasetMetadata, dataResponses, displayableColumns);

            $element.trigger('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE');
            visualization.hideBusyIndicator();

            visualization.render({
              newColumns,
              returnNewComputedColumns,
              newData: dataResponses,
              newVif,
              newTableVif
            });
          }
        );
    }).catch(handleError);
  }

  /**
   * Generates a summary table VIF for Histograms
   *
   * @param {*} newVif
   * @param {*} datasetMetadata
   * @param {*} dataResponses
   * @param {*} displayableColumns
   */
  function generateHistorgramSummaryTableVif(newVif, datasetMetadata, dataResponses, displayableColumns) {
    const newData = dataResponses[0];

    const dimensionColumnName = _.get(newVif, 'series[0].dataSource.dimension.columnName');
    const dimension = _.find(datasetMetadata.columns, (column) => (dimensionColumnName === column.fieldName));

    /** Set dimension to always render as a text for summary tables */
    const tableDimension = _.cloneDeep(dimension);
    _.set(tableDimension, 'renderTypeName', SoQLType.SoQLTextT);

    const tableData = _.cloneDeep(newData);

    /** Merge the start and end of the histogram bucket into one column */
    _.set(tableData, 'rows', newData.rows.map((column) => ([`${column[0]} to ${column[1]}`, column[2]])));

    return generateSummaryTableVif(newVif, displayableColumns, tableData, tableDimension, null);
  }

  function makeSocrataDataRequest(vifToRender, seriesIndex) {
    return getData(vifToRender, seriesIndex).then((data) => ({
      bucketType: data.bucketingOptions.bucketType,
      rows: data.bucketedData.map((row) =>
        [row.start, row.end, row.value]
      ),
      columns: [
        'bucket_start', 'bucket_end', 'measure'
      ]
    }));
  }

  /**
   * Actual execution starts here
   */

  attachEvents();
  updateData(originalVif);

  return this;
};

// Checks a VIF for compatibility with this visualization.
// The intent of this function is to provide feedback while
// authoring a visualization, not to provide feedback to a developer.
// As such, messages returned are worded to make sense to a user.
//
// Returns a Promise.
//
// If the VIF is usable, the promise will resolve.
// If the VIF is not usable, the promise will reject with an object:
// {
//   ok: false,
//   vifValidatorErrors: Array<String>
// }
$.fn.socrataSvgHistogram.validateVif = (vif) =>
  getSoqlVifValidator(vif).then(validator =>
    validator.
      requireExactlyOneSeries().
      requireNumericDimension().
      requireMeasureAggregation().
      toPromise()
  );

export default $.fn.socrataSvgHistogram;
