import { reduce, set } from 'lodash';
import { fetchWithDefaultHeaders, fetchJsonWithParsedError } from 'common/http';
import { ClientContextVariable, ClientContextVariableCreate } from 'common/types/clientContextVariable';
import { Revision } from 'common/types/revision';
import { Parameter, SoQLType, usingNBEName } from 'common/types/soql';
import { V3ClientContextVariableOverrides } from 'common/types/soql/api';

export const createClientContextVariable = (
  viewId: string,
  newParameter: ClientContextVariableCreate
): Promise<ClientContextVariableCreate> => {
  return fetchJsonWithParsedError(`/api/views/${viewId}/client_context`, {
    method: 'POST',
    body: JSON.stringify(newParameter)
  });
};

export const replaceClientContextVariablesOnRevision = (
  viewId: string,
  revisionSeq: number,
  newParameters: ClientContextVariableCreate[]
): Promise<ClientContextVariableCreate[]> => {
  const body = { metadata: { clientContext: { clientContextVariables: newParameters } } };
  return fetchJsonWithParsedError(`/api/publishing/v1/revision/${viewId}/${revisionSeq}`, {
    method: 'PUT',
    body: JSON.stringify(body)
  }).then((response: { resource: Revision }) => {
    const context = response.resource.metadata.clientContext;
    if (context) {
      return context.clientContextVariables;
    }
    return [];
  });
};

export const replaceClientContextVariablesOnView = (
  viewId: string,
  newParameters: ClientContextVariableCreate[]
): Promise<ClientContextVariableCreate[]> => {
  return fetchJsonWithParsedError(`/api/views/${viewId}/client_context`, {
    method: 'PUT',
    body: JSON.stringify(newParameters)
  });
};

export const editClientContextVariable = (
  viewId: string,
  newParameter: ClientContextVariableCreate
): Promise<ClientContextVariableCreate> => {
  return fetchJsonWithParsedError(`/api/views/${viewId}/client_context/${newParameter.name}`, {
    method: 'PATCH',
    body: JSON.stringify(newParameter)
  });
};

export const deleteClientContextVariable = (viewId: string, ccvName: string) => {
  const body = { name: ccvName };
  return fetchWithDefaultHeaders(`/api/views/${viewId}/client_context/${ccvName}`, {
    method: 'DELETE',
    body: JSON.stringify(body)
  });
};

const typeToParamName = (dataType: SoQLType): string => {
  switch (dataType) {
    case SoQLType.SoQLTextT:
      return '$$client_vars_txt';
    case SoQLType.SoQLNumberT:
      return '$$client_vars_num';
    case SoQLType.SoQLFloatingTimestampT:
      return '$$client_vars_ts';
    case SoQLType.SoQLBooleanT:
      return '$$client_vars_bool';
  }
  throw new Error(
    `Tried to get override parameter name for client context variable type ${dataType} which has not been implemented`
  );
};

export const getParameterFunction = (name: string, uid: string): string => {
  return `param(@${uid}, '${name.replace("'", "''")}')`;
};

/**
 * Given an array of client context variable objects, sort by viewId to be sent as query parameters to v3 Soql queries.
 * The result is meant to be used in a POST request body.
 * @see SoQLV3APIOptions
 */
export const toV3OverrideParams = (
  variables: ClientContextVariable[]
): Record<string, V3ClientContextVariableOverrides> | undefined => {
  if (variables.length < 1) {
    return;
  }

  return reduce(
    variables,
    (sortedVariables, { viewId, overrideValue, defaultValue, dataType, name }) => {
      // The query/export API require fully parsed values, but some things along the way
      // may store parameter values as strings.
      let value: string | boolean | number = overrideValue ?? defaultValue;
      if (typeof value === 'string') {
        if (dataType === SoQLType.SoQLBooleanT) {
          value = value === 'true';
        } else if (dataType === SoQLType.SoQLNumberT) {
          value = parseFloat(value);
        }
      }

      // We typically create and save client context variable definitions and report parameters
      // with core's data types. Since the query/export APIs are part of the data stack, swap
      // to those data types.
      const type = usingNBEName(dataType);

      return set(sortedVariables, [viewId, name], { type, value });
    },
    {} as Record<string, V3ClientContextVariableOverrides>
  );
};

/**
 * Given an array of client context variable objects, sort by type to be sent as query parameters with Soql queries.
 * @param variables `viewId` should be set to the view that the variable was created on. If `overrideValue` has a
 *  non-empty value, it will be preferred over `defaultValue`.
 * @returns An object where the keys are the typed query parameter name and the values are URI-encoded strings
 *  representing the client context variables of that type
 */
export const toTypedOverrideParams = (variables: ClientContextVariable[]) => {
  if (variables.length === 0) {
    return {};
  }

  const makeParamForType = (dataType: SoQLType, typedVars: ClientContextVariable[]) => {
    if (typedVars.length === 0) return '';
    const paramName = typeToParamName(dataType);
    const paramValue = typedVars
      .map((tv) => {
        return `${encodeURIComponent(tv.name + '.' + tv.viewId)}=${encodeURIComponent(
          tv.overrideValue ?? tv.defaultValue
        )}`;
      })
      .join('&');
    return [paramName, paramValue];
  };

  const possibleTypes = [
    SoQLType.SoQLTextT,
    SoQLType.SoQLNumberT,
    SoQLType.SoQLBooleanT,
    SoQLType.SoQLFloatingTimestampT
  ];

  return possibleTypes.reduce((params, t) => {
    const varsOfType = variables.filter((v) => v.dataType === t);
    if (varsOfType.length) {
      const [paramName, paramValue] = makeParamForType(t, varsOfType);
      params[paramName] = paramValue;
    }
    return params;
  }, {} as { [typedParameterName: string]: string });
};

export const toTypedOverrideParamString = (variables: ClientContextVariable[]): string => {
  const typedOverrideParams = toTypedOverrideParams(variables);

  return reduce(
    typedOverrideParams,
    (result, value, key) => {
      result.push(`${key}=${encodeURIComponent(value)}`);
      return result;
    },
    [] as string[]
  ).join('&');
};

export const sortClientContextVariables = (
  vars: ClientContextVariable[],
  separateInherited: boolean
): ClientContextVariable[] => {
  const sortFunction = (a: ClientContextVariable, b: ClientContextVariable) => {
    return a.name.localeCompare(b.name);
  };

  if (separateInherited) {
    const inherited = vars.filter((v) => v.inherited);
    const not = vars.filter((v) => !v.inherited);
    return not.sort(sortFunction).concat(inherited.sort(sortFunction));
  } else {
    return vars.sort(sortFunction);
  }
};

export const clientContextVariableToParameter = (ccv: ClientContextVariable): Parameter => ({
  type: 'param',
  name: ccv.name,
  table: ccv.viewId
});

// dsmapi gets unhappy if we send the extra attributes that ClientContextVariable has (like viewId)
export const clientContextVariablesToCreateOnly = (
  ccvs: ClientContextVariable[]
): ClientContextVariableCreate[] =>
  ccvs.map((ccv) => {
    return {
      name: ccv.name,
      displayName: ccv.displayName,
      dataType: ccv.dataType,
      defaultValue: ccv.defaultValue
    };
  });
