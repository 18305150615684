import { ColumnFormat } from 'common/types/viewColumn';
import { momentDateFormatToDatePickerDateFormat } from 'common/dates';
import moment from 'moment';
import React, { ReactElement } from 'react';
import type { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Ed... Remove this comment to see the full error message
import EditCell from './EditCell';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Ty... Remove this comment to see the full error message
import TypedCell from './TypedCell';
import { TIME_FORMATS } from 'common/DataTypeFormatter';
import { SoQLType } from 'common/types/soql';

type DateColumnFormat = ColumnFormat & { view?: string };

interface Props {
  isDropping: boolean;
  isError: boolean;
  isEditing: boolean;
  updateCell: () => void;
  value: string;
  format: DateColumnFormat;
  type: SoQLType;
}

const DateCell: FunctionComponent<Props> = ({ isEditing, value, updateCell, format, isDropping, type }) => {
  const momentDateFormat =
    (format.view ? TIME_FORMATS[format.view] : false) || TIME_FORMATS.default_date_time;
  const datePickerDateFormat = momentDateFormatToDatePickerDateFormat(momentDateFormat);

  const wantsUTC = format.view === 'iso_8601_date_time_utc' || format.view === 'iso_8601_date_utc';

  const getDateOrNull = (checkIfDate: string) => {
    const maybeDate = moment(checkIfDate, moment.ISO_8601); // we need to pass in the format, or else invalid strings get huge deprecation warnings
    return maybeDate.isValid() ? maybeDate.toDate() : null;
  };

  if (isEditing) {
    const renderer = (name: string, initialValue: string, change: (v: string) => void): ReactElement => (
      <DatePicker
        key="date-editor"
        name="date-editor"
        showTimeSelect
        timeIntervals={15}
        dateFormat={datePickerDateFormat}
        timeCaption="time"
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        utcOffset={0}
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'flip',
            options: {
              placement: 'bottom' // don't allow it to flip to be above. doesn't work with the drag to resize container.
            }
          },
          {
            name: 'preventOverflow',
            options: {
              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            }
          },
          {
            name: 'hide',
            options: {
              enabled: false // turn off since needs preventOverflow to be enabled
            }
          }
        ]}
        selected={getDateOrNull(initialValue)}
        onChange={(newValue: Date | null): void => {
          if (newValue) {
            const newDateFormat = `YYYY-MM-DDTHH:mm:ss.SSS${
              type === SoQLType.SoQLFixedTimestampT ? 'ZZ' : ''
            }`;
            return change(`${moment(newValue).format(newDateFormat)}`);
          }
        }}
      />
    );

    return <EditCell value={value} updateCell={updateCell} renderInput={renderer} />;
  } else {
    let text = value;
    if (value) {
      let m = moment(value);
      m = wantsUTC ? m.utc() : m;
      text = m.format(momentDateFormat);
    }
    return <TypedCell isDropping={isDropping} value={text} format={format} />;
  }
};

export default DateCell;
