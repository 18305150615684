import _ from 'lodash';
import React, { Component, PropsWithChildren, ReactNode, RefObject } from 'react';
import classNames from 'classnames';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.scss';

import { getPublishedViewUid } from 'common/views/publicationHelpers';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import getRevisionSeq from 'common/js_utils/getRevisionSeq';
import { isStory, isStoryDraft } from 'common/views/view_types';
import { assetIdFor, fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';
import airbrake from 'common/airbrake';
import { assetIsDraft } from 'common/views/helpers';
import { isMobileOrTablet } from 'common/visualizations/helpers/MediaQueryHelper';
import { anyActionAvailable } from 'common/components/AssetActionBar/lib/any_action_available';
import { getAllowedSecondaryActions } from 'common/components/AssetActionBar/lib/secondary_actions';
import { getPrimaryAction } from 'common/components/AssetActionBar/lib/primary_action';
import { currentUserCanMakeAssets } from 'common/current_user';

import CreateViewButton from './components/create_view_button';
import FederationTargets from './components/federation_targets';
import PublicationAction, { RenderPrimaryButtonFunction } from './components/publication_action';
import PublicationState from './components/publication_state';
import SaveViewButton from './components/save_view_button';
import ViewOnSourceDomainButton from './components/view_on_source_domain_button';
import { View, ViewPermissions } from 'common/types/view';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import InteractiveUser from 'common/types/users/interactiveUser';
import { assetStatusFor } from 'common/views/asset_status';

const queryClient = new QueryClient();

export interface AssetActionBarProps {
  user: InteractiveUser;
  view: View;
  publishedViewUid?: string;
  editDraftButton?: ReactNode;

  /** Optional, called if user selects "Copy" from the more actions menu. */
  onCopy?: (name: string) => void;
  /** Optional, shows the 'Based On' caption on the copy modal */
  copyBasedOn?: string;

  onPublish?: () => (permissions?: ViewPermissions) => void;

  renderPrimaryButton?: RenderPrimaryButtonFunction;

  /** Optional, children to place at the left of the bar (i.e., a menu drawer button). */
  leftChildren?: ReactNode;

  /**
   * Optional, will fetch internally if this is not provided. Some contexts using A2B
   * already have this information available - we can avoid making a duplicate request
   * this way.
   */
  approvalsGuidance?: GuidanceSummaryV2;
  showDSLPButton?: boolean;

  /**
   * Called once if the bar has decided it isn't going to show anything. This is
   * sometimes helpful when the surrounding application needs to show legacy
   * components if the asset action bar decides to hide. For anything beyond
   * simple usages, it is recommended to call anyActionAvailable directly to see
   * if the A2B will decide to render.
   *
   * Current uses of this callback don't have direct access to approvalsGuidance
   * and thus rely on A2B to fetch it itself, so calling anyActionAvailable is
   * not trivial.
   */
  willNotShowBarBecauseUserCantDoAnything?: () => void;

  /**
   * Called once if the bar has decided it will show up. Used for same scenarios
   * as willNotShowBarBecauseUserCantDoAnything.
   */
  willShowBar?: () => void;

  customSaveFunc?: () => void;

  inVQE?: boolean;
}

interface AssetActionBarState {
  mobileMenuOpen: boolean;
  userCanCreateMoreAssets: boolean;
  approvalsGuidance?: GuidanceSummaryV2;
}

class AssetActionBar extends Component<PropsWithChildren<AssetActionBarProps>, AssetActionBarState> {
  willNotShowBarBecauseUserCantDoAnything: () => void;
  willShowBar: () => void;
  elRef: RefObject<HTMLDivElement>;

  constructor(props: PropsWithChildren<AssetActionBarProps>) {
    super(props);

    const { view } = this.props;

    if (!view) {
      throw new Error('The Asset Action Bar does not make much sense without a current view.');
    }

    if (isStory(view) && !window.STORY_DATA) {
      airbrake.notify({
        error: 'Using Asset Action Bar on a Story requires a window object.',
        context: { component: 'AssetActionBar' } // provide useful information here about where the error occurred
      });
    }

    this.elRef = React.createRef();
    this.willNotShowBarBecauseUserCantDoAnything = _.once(
      props.willNotShowBarBecauseUserCantDoAnything || _.noop
    );
    this.willShowBar = _.once(props.willShowBar || _.noop);

    this.state = {
      mobileMenuOpen: false,
      userCanCreateMoreAssets: false
    };
  }

  onDocumentKeyUp = (e: KeyboardEvent) => {
    // Close mobile dropdown menu on ESCAPE keypress.
    if (e.keyCode === 27) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  onDocumentClick = (e: MouseEvent) => {
    // Close mobile dropdown menu on click outside AssetActionBar
    // @ts-expect-error TS(2345) FIXME: Argument of type 'EventTarget | null' is not assig... Remove this comment to see the full error message
    if (this.elRef.current && !this.elRef.current.contains(e.target)) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  onWindowResize = () => {
    this.forceUpdate();
  };

  fetchUserCanMakeAssets = async () => {
    const userCanCreateMoreAssets = await currentUserCanMakeAssets();
    this.setState({ userCanCreateMoreAssets });
  };

  componentDidMount() {
    document.addEventListener('keyup', this.onDocumentKeyUp);
    document.addEventListener('click', this.onDocumentClick);
    window.addEventListener('resize', this.onWindowResize);

    if (!this.props.approvalsGuidance) {
      fetchApprovalsGuidanceV2(
        assetIdFor(this.props.view.id, getRevisionSeq(), isStoryDraft(this.props.view))
      ).then((approvalsGuidance: GuidanceSummaryV2) => {
        this.setState({ approvalsGuidance });
      });
    }

    this.fetchUserCanMakeAssets();
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onDocumentKeyUp);
    document.removeEventListener('click', this.onDocumentClick);
    window.removeEventListener('resize', this.onWindowResize);
  }

  renderFederationTargets() {
    const { view } = this.props;

    // don't render federation targets if we're on a draft
    if (assetIsDraft({ coreView: view })) {
      return null;
    }

    const hasFederationTargets = view.federatedTo && view.federatedTo.length > 0;
    if (hasFederationTargets) {
      return [
        <div className="divider" key="federation-targets-divider" />,
        <FederationTargets view={view} key="federation-targets" />
      ];
    } else {
      return [];
    }
  }

  renderLeftSideInformation(approvalsGuidance: GuidanceSummaryV2) {
    const { leftChildren, view } = this.props;
    return (
      <div className={classNames({ 'left-side': true, mobile: isMobileOrTablet() })}>
        {leftChildren}
        <div className="asset-action-bar-item">
          <span className="asset-name forge-typography--title" title={_.get(view, 'name')}>
            {_.get(view, 'name')}
          </span>
        </div>
        <div className="divider" />
        <PublicationState view={view} approvalsGuidance={approvalsGuidance} />
        {this.renderFederationTargets()}
      </div>
    );
  }

  renderRightSideActions(approvalsGuidance: GuidanceSummaryV2) {
    const {
      children,
      editDraftButton,
      onCopy,
      copyBasedOn,
      onPublish,
      renderPrimaryButton,
      showDSLPButton,
      view,
      customSaveFunc,
      inVQE
    } = this.props;

    const { mobileMenuOpen, userCanCreateMoreAssets } = this.state;
    const publishedViewUid = this.props.publishedViewUid || getPublishedViewUid(view, approvalsGuidance);
    const assetStatus = assetStatusFor(view, approvalsGuidance);

    /*
     * The AssetActionBar component is divided up into multiple logical
     * UI groups, each group consisting of UI components that may require
     * the user to have a certain set of permissions or the asset to be in
     * a particular state (published vs draft, etc).
     *
     * In order to hide UI that the user can't make use of, we have a
     * concept of "allowed actions" that take into account both rights
     * and asset state, as well as business logic (such as preferring
     * a publication button over "edit draft", even though both are
     * allowable).
     *
     * The AssetActionBar will hide itself entirely if *no* actions are allowed. This is one reason why
     * this central file exists, vs. spreading out this logic throughout the different parts of AAB.
     * Special: If AssetActionBar is displayed in VQE, AAB will only hide itself entirely if there are
     * *no* actions allowed and if there are no child components. A special VQE-only `Create View`
     * button is passed as a child to AAB and will be rendered for any logged-in user.
     */
    const allowedActions = getAllowedSecondaryActions({
      approvalsGuidance,
      assetStatus,
      view,
      publishedViewUid,
      userCanCreateMoreAssets,
      showDSLPButton
    });

    const primaryAction = getPrimaryAction({
      appProvidesOwnPrimaryButton: !!renderPrimaryButton,
      assetStatus,
      approvalsGuidance,
      view,
      showDSLPButton
    });

    if (!anyActionAvailable(primaryAction, allowedActions) && !(inVQE && children)) {
      return null;
    }

    const rightSideParts: ReactNode[] = [];

    if (allowedActions.viewOnSourceDomain) {
      rightSideParts.push(<ViewOnSourceDomainButton key="view-on-source-domain-button" view={view} />);
    }

    if (allowedActions.createChildView) {
      // looking at a published grid view dataset, we show the "Create View" button
      rightSideParts.push(<CreateViewButton key="create-view-button" />);
    }

    if (allowedActions.save) {
      // looking at an unpublished grid view dataset that we have the "write" right for,
      // we show the "Save" button
      rightSideParts.push(<SaveViewButton key="save-view-button" />);
    }

    rightSideParts.push(
      <PublicationAction
        key="publication-action"
        primaryAction={primaryAction}
        allowedActions={allowedActions}
        approvalsGuidance={approvalsGuidance}
        publishedViewUid={publishedViewUid}
        view={view}
        editDraftButton={editDraftButton}
        renderPrimaryButton={renderPrimaryButton}
        onCopy={onCopy}
        copyBasedOn={copyBasedOn}
        onPublish={onPublish}
        customSaveFunc={customSaveFunc}
      />
    );

    const rightSide = (additionalClassNames?: string) => (
      <div className={`right-side ${additionalClassNames || ''}`}>
        {children}
        {rightSideParts}
      </div>
    );

    const handleMobileMenuButtonClick = () => {
      this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
    };

    if (isMobileOrTablet()) {
      return (
        <div className="right-side-mobile">
          <button
            className="btn btn-transparent asset-action-bar-mobile-dropdown-button"
            onClick={handleMobileMenuButtonClick}
          >
            <SocrataIcon name={IconName.Hamburger} />
          </button>
          {rightSide(mobileMenuOpen ? '' : 'hidden')}
        </div>
      );
    } else {
      return rightSide();
    }
  }

  render() {
    const { user } = this.props;
    const approvalsGuidance = this.state.approvalsGuidance || this.props.approvalsGuidance;

    // Anonymous users cannot take any action via AAB, and assets
    // without guidance cannot be acted upon by AAB
    if (!user || !approvalsGuidance) {
      this.willNotShowBarBecauseUserCantDoAnything();
      return null;
    }

    const rightSideActions = this.renderRightSideActions(approvalsGuidance);
    if (!rightSideActions) {
      // User won't be shown anything, so hide the entire bar!
      this.willNotShowBarBecauseUserCantDoAnything();
      return null;
    }

    this.willShowBar();

    return (
      <QueryClientProvider client={queryClient}>
        <div className="asset-action-bar" ref={this.elRef}>
          {this.renderLeftSideInformation(approvalsGuidance)}
          {rightSideActions}
        </div>
      </QueryClientProvider>
    );
  }
}

export default AssetActionBar;
