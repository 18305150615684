import React, { FC, useEffect, useRef } from 'react';

import VisualizationRenderer from 'common/visualizations/VisualizationRenderer.js';
import type { Vif } from '../vif';
import type { VisualizationOptions } from 'common/visualizations/views/BaseVisualization/types';

export interface VisualizationProps {
  vif: Vif;
  options?: VisualizationOptions;
  onVisualizationRenderError?: (error: any) => void;
  onVifUpdated?: () => void;
}

const Visualization: FC<VisualizationProps> = (props) => {
  const { vif, options, onVisualizationRenderError, onVifUpdated } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const visRef = useRef<VisualizationRenderer | null>(null);

  // If anything has changed, and we don't have a visualization, create one.
  // If a viz crashed, we should try to recreate it, because changes to the vif might have fixed the issue.
  useEffect(() => {
    if (!visRef.current && elementRef.current) {
      try {
        visRef.current = new VisualizationRenderer(vif, elementRef.current, options);
      } catch (error) {
        if (onVisualizationRenderError) {
          onVisualizationRenderError(error);
        } else {
          throw error;
        }
      }
    }
  }, [vif, options, onVisualizationRenderError]);

  // Clean up the visualization when the component is unmounted.
  // If we follow the docs perfectly, this should go in the useEffect above, but it doesn't work.
  // Doing that would destroy the viz anytime anything changes, which is not what we want.
  useEffect(() => {
    return () => {
      visRef.current?.destroy();
    };
  }, []);

  // Run the callback when the visualization fires the event
  useEffect(() => {
    if (elementRef.current && onVifUpdated) {
      const localRef = elementRef.current;
      localRef.addEventListener('SOCRATA_VISUALIZATION_VIF_UPDATED', onVifUpdated);
      return () => {
        localRef.removeEventListener('SOCRATA_VISUALIZATION_VIF_UPDATED', onVifUpdated);
      };
    }
  }, [onVifUpdated]);

  // Update the visualization every render
  try {
    visRef.current?.update(vif, options);
  } catch (error) {
    if (onVisualizationRenderError) {
      onVisualizationRenderError(error);
    } else {
      throw error;
    }
  }

  return <div className="common-viz-react" ref={elementRef} />;
};

export default Visualization;
