import I18n from 'common/i18n';
import { get, includes, toLower, values } from 'lodash';

import { FilterDataType } from '../../types';
import { ParameterConfiguration } from 'common/types/reportFilters';
import {
  BinaryOperator,
  FILTER_FUNCTION,
  NumberSoqlFilter,
  RadiusSoqlFilter,
  RelativeDateFilter,
  SoqlFilter,
  TimeRangeFilter,
  OPERATOR
} from '../../SoqlFilter';
import { SoQLType } from 'common/types/soql';
import { getCheckboxFilterHumanText } from './CheckboxFilter';
import { getNumberFilterHumanText } from './NumberFilter';
import { getTextFilterHumanText } from './TextFilter';
import { getRadiusFilterHumanText } from './RadiusFilter';
import { RELATIVE_FILTERS, formatDate } from 'common/dates';
import {
  getDateRangeFilterText,
  getRelativeDateFilterText,
  getSingleSelectDateFilterText
} from './CalendarDateFilter';
import { FilterColumnsMap } from './BaseFilter';
import { TextSoqlFilter } from './TextFilter';
import { ComputedColumnSoqlFilter } from './ComputedColumnFilter';
import { FILTER_TYPES, SINGLE_SELECT_BY } from 'common/dates';
import { ClientContextVariable } from 'common/types/clientContextVariable';

export function getParameterHumanText(parameter: ParameterConfiguration) {
  switch (get(parameter, 'dataType')) {
    case SoQLType.SoQLFloatingTimestampT:
    case SoQLType.SoQLFixedTimestampT:
      const parameterStartDate = parameter.overrideValue;

      // if parameterStartDate is a relative value
      if (
        parameterStartDate === RELATIVE_FILTERS.TODAY ||
        parameterStartDate === RELATIVE_FILTERS.YESTERDAY
      ) {
        const scope = 'shared.components.filter_bar.calendar_date_filter';
        return I18n.t(`relative_periods.${parameterStartDate}`, { scope });
      }
      return formatDate(parameterStartDate, 'l');
    case SoQLType.SoQLTextT:
    case SoQLType.SoQLNumberT:
      return parameter.overrideValue;
    case SoQLType.SoQLBooleanT:
      return parameter.displayName;
  }
}

/** Get the human label text based on the current values */
export function getFilterHumanText(
  config: SoqlFilter,
  columns: FilterColumnsMap,
  showNullsAsFalse?: boolean
): string {
  const filterFunction = config.function;
  const formattingColumn = values(columns)[0];

  if (filterFunction === FILTER_FUNCTION.NOOP) {
    return I18n.t('shared.components.filter_bar.select');
  }
  switch (config.dataTypeName) {
    case FilterDataType.CHECKBOX:
      return getCheckboxFilterHumanText(config as BinaryOperator, showNullsAsFalse);
    case FilterDataType.DATE:
      if (config.singleSelect) {
        // It may be possible to simply reverse the order of first 2 if..else if conditions,
        // but this is a special condition and wanted to make sure there were no side effect.
        if (
          get(config, 'singleSelect.by') === SINGLE_SELECT_BY.DAY &&
          get(config, 'arguments.calendarDateFilterType') === FILTER_TYPES.RELATIVE
        ) {
          return getRelativeDateFilterText(config as RelativeDateFilter);
        }
        return getSingleSelectDateFilterText(config as TimeRangeFilter, columns);
      } else if (get(config.arguments, 'calendarDateFilterType') === FILTER_TYPES.RELATIVE) {
        return getRelativeDateFilterText(config as RelativeDateFilter);
      } else {
        return getDateRangeFilterText(config as TimeRangeFilter, columns);
      }
    case FilterDataType.NUMBER:
      return getNumberFilterHumanText(config as NumberSoqlFilter, formattingColumn);
    case FilterDataType.RADIUS:
      return getRadiusFilterHumanText(config as RadiusSoqlFilter);
    case FilterDataType.TEXT:
    case FilterDataType.COMPUTED:
      return getTextFilterHumanText(config as BinaryOperator);
    default: // eslint-disable-line no-console
      console.error(`Unsupported filter type "${config.dataTypeName}"`);
      return '';
  }
}

export function groupAllFiltersByDataset(allFilters: SoqlFilter[]) {
  // We need to group allFilters by dataset ID since soqlDataProvider is instantiated per dataset,
  // and soqlDataProvider takes allFilters as single data source Filters type or new SoqlFilter type.
  const allFiltersByDataset = allFilters.reduce((acc, filter): Record<string, SoqlFilter[]> => {
    (filter as SoqlFilter).columns.forEach((column) => {
      const { datasetUid } = column;
      if (!acc[datasetUid]) {
        acc[datasetUid] = [];
      }
      // We need to include only the columns that are relevant to the dataset,
      // so we can overwrite "columns" property with an array that contains only the current column.
      acc[datasetUid].push({ ...filter, columns: [column] });
    });

    return acc;
  }, {} as Record<string, SoqlFilter[]>);

  return allFiltersByDataset;
}

export function groupClientContextVariablesByDataset(allParameters: ParameterConfiguration[]) {
  const variablesByDataset: Record<string, ClientContextVariable[]> = {};

  allParameters.forEach(({ paramIds, overrideValue, dataType, displayName }) => {
    paramIds.forEach(({ datasetUid, name, inheritedFromUid }) => {
      if (!variablesByDataset[datasetUid]) {
        variablesByDataset[datasetUid] = [];
      }
      variablesByDataset[datasetUid].push({
        overrideValue: overrideValue,
        dataType: dataType,
        name,
        defaultValue: overrideValue,
        displayName,
        viewId: inheritedFromUid ?? datasetUid,
        inherited: !!inheritedFromUid
      });
    });
  });

  return variablesByDataset;
}

export function isEqualityOperator(operator: OPERATOR) {
  return (
    operator !== OPERATOR.CONTAINS &&
    operator !== OPERATOR.DOES_NOT_CONTAIN &&
    operator !== OPERATOR.STARTS_WITH
  );
}

export function isEqualityFilter(filter: TextSoqlFilter | ComputedColumnSoqlFilter) {
  const operator = getOperator(filter);
  return isEqualityOperator(operator);
}

export function getOperator(filter: TextSoqlFilter | ComputedColumnSoqlFilter) {
  const firstOperator = get(filter, 'arguments[0].operator');
  if (includes([OPERATOR.NULL, OPERATOR.EQUALS], firstOperator)) {
    return OPERATOR.EQUALS;
  } else if (includes([OPERATOR.NOT_NULL, OPERATOR.NOT_EQUAL], firstOperator)) {
    return OPERATOR.NOT_EQUAL;
  } else if (firstOperator === undefined) {
    // This means that there are no values saved yet, so we need to derive the operator based on the joinOn
    return toLower((filter as BinaryOperator).joinOn) === 'and' ? OPERATOR.NOT_EQUAL : OPERATOR.EQUALS;
  }

  return firstOperator;
}
