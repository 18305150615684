import _ from 'lodash';
import { assert } from 'common/assertions';
import FeatureFlags from 'common/feature_flags';

import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import {
  SCATTER_CHART_COLOR_BY_SERIES_INDEX,
  SCATTER_CHART_RESIZE_BY_SERIES_INDEX,
  SCATTER_CHART_Y_AXIS_SERIES_INDEX,
  SERIES_TYPE_SCATTER_CHART
} from 'common/visualizations/views/SvgConstants';
import { getDrilldowns, shouldRenderDrillDown } from 'common/visualizations/helpers/VifSelectors';
import MetadataProvider, {
  getDisplayableColumns
} from 'common/visualizations/dataProviders/MetadataProvider';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import { Vif, DataRow, V1TableVif } from '../vif';
import { OPERATOR, SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { ViewColumn } from 'common/types/viewColumn';
import { FilterItemType, FilterParameterConfiguration } from 'common/types/reportFilters';
import * as BaseFilter from 'common/components/FilterBar/lib/Filters/BaseFilter';
import * as TextFilter from 'common/components/FilterBar/lib/Filters/TextFilter';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { SoQLType } from 'common/types/soql';
import { getTodayDate, getYesterdayDate, RELATIVE_FILTERS } from 'common/dates';

const DEFAULT_VIF = {
  configuration: {
    axisLabels: {
      top: false,
      right: false,
      bottom: false,
      left: false
    },
    localization: {}
  },
  createdAt: null,
  description: null,
  format: {
    type: 'visualization_interchange_format',
    version: VIF_CONSTANTS.LATEST_VERSION
  },
  series: [],
  title: null
};

/**
 * Public methods
 */

export function getDefaultVif() {
  return _.cloneDeep(DEFAULT_VIF);
}
/**
 * Retrieves constraints to be used by the filter bar
 * Constraints:
 *     - geoSearch: RadiusFilter is constrained to the latLng boundary set in the vif.
 * Returns:
 * {
 *     geoSearch: { boundary: [0, 0, 10, 10] },
 * }
 */
export function getFilterConstraints(vif: Vif) {
  const boundary = [
    _.get(vif, 'configuration.basemapOptions.searchBoundaryUpperLeftLongitude'),
    _.get(vif, 'configuration.basemapOptions.searchBoundaryLowerRightLatitude'),
    _.get(vif, 'configuration.basemapOptions.searchBoundaryLowerRightLongitude'),
    _.get(vif, 'configuration.basemapOptions.searchBoundaryUpperLeftLatitude')
  ];

  if (_.every(boundary, _.isNumber)) {
    return {
      geoSearch: { boundary }
    };
  } else {
    if (!_.isEmpty(_.without(boundary, undefined))) {
      console.warn("Boundaries won't be set if there are missing values.");
    }

    return {};
  }
}

export function getSanitizeFilterParameterConfig(
  filters: SoqlFilter[],
  vif: Vif
): FilterParameterConfiguration[] {
  const drilldowns = getDrilldowns(vif);

  if (_.isEmpty(drilldowns) && !_.isEmpty(filters)) {
    return _.map(filters, (filter) => {
      return {
        type: FilterItemType.FILTER,
        config: _.merge({}, filter, { isDrilldown: false })
      } as FilterParameterConfiguration;
    });
  }
  return _.map(filters, (filter) => {
    return {
      type: FilterItemType.FILTER,
      config: filter
    } as FilterParameterConfiguration;
  });
}

export function mergeFilters(srcFilters: SoqlFilter[], overrideFilters: SoqlFilter[]) {
  let output: SoqlFilter[] = _.cloneDeep(srcFilters);

  overrideFilters.forEach((filter) => {
    if (filter.function === 'noop') {
      // Do not merge in overrideFilters which do not have a value applied
      return;
    }

    // Enforce one filter per column
    output = _.reject(output, (srcFilter) => {
      return (srcFilter as SoqlFilter).columns[0].fieldName === (filter as SoqlFilter).columns[0].fieldName;
    }) as SoqlFilter[];

    const modifiedFilter: SoqlFilter = _.cloneDeep(filter);
    // Set the filter is overridden by global filter bar.
    _.set(modifiedFilter, 'isOverridden', true);

    output.push(modifiedFilter);
  });
  return output;
}

export function applyAdditionalFiltersToVif(vif: Vif, additionalFilters: Record<string, SoqlFilter[]>) {
  if (_.isEmpty(additionalFilters)) {
    return vif;
  }

  const updatedVif = _.cloneDeep(vif);
  updatedVif.series.forEach((series) => {
    if (series.dataSource.type !== 'socrata.soql') {
      return;
    }
    const additionalFiltersForSeries = additionalFilters[series.dataSource.datasetUid];
    if (additionalFiltersForSeries) {
      // TODO: inline? others?
      assert(series.dataSource.type === 'socrata.soql');
      _.set(
        series,
        'dataSource.filters',
        mergeFilters(_.get(series, 'dataSource.filters', []), additionalFiltersForSeries)
      );
    }
  });

  return updatedVif;
}

/**
 * Called within Storyteller after AdditionalFilters has been merged into the
 * migrated VIF
 */
export function applyParameterOverridesToVif(
  vif: Vif,
  parameterOverrides?: Record<string, ClientContextVariable[]>
) {
  if (_.isEmpty(parameterOverrides) || _.isNil(parameterOverrides)) {
    return vif;
  }
  const updatedVif = _.cloneDeep(vif);

  updatedVif.series.forEach((series) => {
    if (series.dataSource.type !== 'socrata.soql') {
      return;
    }
    // normalize values for use in queries
    const parameterOverridesForSeries = (parameterOverrides[series.dataSource.datasetUid] ?? []).map(
      (clientContextVariable) => {
        if (clientContextVariable.dataType === SoQLType.SoQLFloatingTimestampT) {
          if (clientContextVariable.overrideValue === RELATIVE_FILTERS.TODAY) {
            const today = getTodayDate();

            return {
              ...clientContextVariable,
              overrideValue: today
            };
          }

          if (clientContextVariable.overrideValue === RELATIVE_FILTERS.YESTERDAY) {
            const yesterday = getYesterdayDate();

            return {
              ...clientContextVariable,
              overrideValue: yesterday
            };
          }
        }

        return clientContextVariable;
      }
    );

    if (parameterOverridesForSeries) {
      _.set(series, 'dataSource.parameterOverrides', parameterOverridesForSeries);
    }
  });

  return updatedVif;
}

/** Get an array of unique datasets powering vif series */
export function getVifSeriesDatasets(vif: Vif | V1TableVif) {
  const migratedVif = migrateVif(vif);
  const datasets = _.reduce(
    migratedVif.series,
    (result, series) => {
      if (series.dataSource.type !== 'socrata.soql') {
        return result;
      }
      result[series.dataSource.datasetUid] = true;
      return result;
    },
    {}
  );

  return _.keys(datasets);
}

/**
 * Private methods
 */

export function migrateScatterChartVifWithoutRequiredSeries(vif: Vif) {
  if (!vif && _.get(vif, 'series', []).length < SCATTER_CHART_Y_AXIS_SERIES_INDEX) {
    return vif;
  }
  const colorBySeriesAvailable =
    _.get(vif, ['series', SCATTER_CHART_COLOR_BY_SERIES_INDEX, 'type']) === SERIES_TYPE_SCATTER_CHART;

  const resizeBySeriesAvaibale =
    _.get(vif, ['series', SCATTER_CHART_RESIZE_BY_SERIES_INDEX, 'type']) === SERIES_TYPE_SCATTER_CHART;

  const getEmptyScatterPlotSeries = () => {
    const emptyScatterPlotSeries = _.cloneDeep(_.get(vif, 'series[0]'));
    _.set(emptyScatterPlotSeries.dataSource, 'measure.columnName', null);

    return emptyScatterPlotSeries;
  };

  if (!colorBySeriesAvailable) {
    vif.series.splice(SCATTER_CHART_COLOR_BY_SERIES_INDEX, 0, getEmptyScatterPlotSeries());
  }

  if (!resizeBySeriesAvaibale) {
    vif.series.splice(SCATTER_CHART_RESIZE_BY_SERIES_INDEX, 0, getEmptyScatterPlotSeries());
  }

  return vif;
}

export function getInlineTableVif(
  rows: DataRow[],
  columns: ViewColumn[],
  startIndex: number,
  numberOfRows: number
) {
  return {
    configuration: {
      order: [], // TODO keep track of order changes (vis sends out an event?)
      viewSourceDataLink: false
    },
    format: {
      type: 'visualization_interchange_format',
      version: 3
    },
    series: [
      {
        dataSource: {
          type: 'socrata.inline',

          // no matter what, we only ever show numberOfRows
          // (there is also basic logic to add a LIMIT to the query for this but we can't always rely on it)
          startIndex: startIndex,
          endIndex: numberOfRows,
          totalRowCount: numberOfRows,

          // default to empty array if we don't have any rows
          rows: rows ? rows : [],

          // _technically_ there's a lot of other stuff we could pass in here but columns is all we care about right now
          view: { columns }
        },
        label: null,
        type: 'table',
        unit: {
          one: 'row',
          other: 'rows'
        }
      }
    ],
    title: null
  };
}

export function getRawSoqlTableVif(domain: string, datasetUid: string, soqlQuery: string) {
  return {
    configuration: {
      order: [],
      viewSourceDataLink: false
    },
    format: {
      type: 'visualization_interchange_format',
      version: 3
    },
    series: [
      {
        dataSource: {
          datasetUid,
          rawSoqlQuery: soqlQuery,
          domain,
          type: 'socrata.rawSoql'
        },
        label: null,
        type: 'table',
        unit: {
          one: 'row',
          other: 'rows'
        }
      }
    ],
    title: null
  };
}

export function getColumnsForFilterWithVif(columnName: string, vif: Vif) {
  const datasetUid = _.get(vif, 'series[0].dataSource.datasetUid');
  return {
    columns: [{ fieldName: columnName, datasetUid }]
  };
}

export function appendDimensionFilters(vif: Vif, dimensions: string[], columns: ViewColumn[]) {
  const limit = _.get(vif, 'series[0].dataSource.limit');

  if (!_.isNumber(limit) || limit > 20 || !dimensions.length) {
    return vif;
  }

  _.forEach(vif.series, (seriesItem) => {
    const currentDrilldownColumnName = seriesItem?.dataSource?.dimension?.currentDrilldownColumnName;
    const columnName =
      shouldRenderDrillDown(vif) && currentDrilldownColumnName
        ? currentDrilldownColumnName
        : seriesItem?.dataSource?.dimension?.columnName;
    const datasetUid =
      seriesItem?.dataSource?.type === 'socrata.soql' ? seriesItem.dataSource.datasetUid : null;
    const column = columns.find(({ fieldName }) => fieldName === columnName);

    if (_.isNil(columnName) || _.isNil(datasetUid) || _.isNil(column)) {
      return;
    }

    const noopFilter = BaseFilter.getNoopFilter({ [datasetUid]: column });
    const dimensionFilter = TextFilter.getEqualityTextFilter(noopFilter as TextFilter.TextSoqlFilter, {
      operator: OPERATOR.EQUALS,
      values: dimensions
    });

    _.set(seriesItem, 'dataSource.filters', [...(seriesItem?.dataSource?.filters ?? []), dimensionFilter]);
  });

  return vif;
}

/**
 * In edit mode migration occurs in index.js for the editor page on load
 * In view mode the migration occurs componentSocrataVisualizationTable JQuery component
 * @param vif This could be an inline table VIF of any version. Usually it will be version 1.
 */
export async function migrateInSituTables(vif: any) {
  // Migrate original vif to latest version
  const modifiedVif = migrateVif(_.cloneDeep(vif));
  const datasetUid = _.get(modifiedVif, 'series[0].dataSource.datasetUid');
  const metadataProvider = new MetadataProvider({ datasetUid }, true);
  const datasetMetadata = await metadataProvider.getDatasetMetadata();

  const allDatasetColumns = getDisplayableColumns(datasetMetadata);
  const modifiedSeries = [
    {
      type: 'agTable',
      unit: { one: 'row', other: 'rows' },
      color: {},
      label: null,
      dataSource: {
        datasetUid: datasetUid,
        dimension: {
          columns: allDatasetColumns,
          columnName: null,
          aggregationFunction: null
        },
        filters: _.get(modifiedVif, 'series[0].dataSource.filters'),
        hierarchies: [],
        measure: {
          columnName: null,
          aggregationFunction: 'count'
        },
        type: 'socrata.soql'
      }
    }
  ];

  const config = {
    viewSourceDataLink: true,
    showDataTableControl: false
  };

  _.set(modifiedVif, 'series', modifiedSeries);
  _.set(modifiedVif, 'configuration', config);
  _.set(modifiedVif, 'title', '');
  return modifiedVif;
}

export const removeTransientStateFromVif = (vif: Vif) => {
  const newVif = _.cloneDeep(vif);
  const seriesType = _.get(newVif, 'series[0].type');

  if (seriesType === 'calendar') {
    _.unset(newVif, 'configuration.currentDisplayDate');
  }

  return newVif;
};
