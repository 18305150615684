import { find, get } from 'lodash';
import { useMemo } from 'react';
import {
  ColDef,
  ITooltipParams,
  CellClassParams,
  ValueGetterParams,
  HeaderClassParams,
  HeaderValueGetterParams
} from '@ag-grid-community/core';

import {
  getAgTableHeaderName,
  getCustomCellStyle,
  getCustomHeaderStyle,
  getFieldName
} from '../helpers/TableColumnFormatting';
import { GroupAndTotalRenderer } from '../GroupAndTotalRenderer';
import I18n from 'common/i18n';
import { NULL_GROUPING } from '../Constants';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { generateCellClasses } from 'common/visualizations/views/agGridReact/helpers/TableExportFormatting';

export interface AutoGroupAttributesProps {
  datasetUid: string;
  domain: string;
  isIndented?: boolean;
  singleAutoColumnWidth?: number;
}

const getHeaderValue = (params: HeaderValueGetterParams, isIndented: boolean | undefined) => {
  const { columnMetadata, columnFormats } = params.context as CustomAgGridContext;
  let fieldName = '';
  if (isIndented) {
    fieldName = params?.api.getRowGroupColumns()[0].getColDef().field ?? '';
  } else {
    // @ts-expect-error params.colDef can be a single column or a row group, but the type doesn't include specific fields from either
    fieldName = params?.colDef?.field || params?.colDef?.showRowGroup;
  }

  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);
  if (!localColumnMetadata) return params.colDef.headerName;
  return getAgTableHeaderName(columnFormats[fieldName], localColumnMetadata);
};

const getHeaderClass = (params: HeaderClassParams, isIndented: boolean | undefined) => {
  const { columnMetadata, columnFormats } = params.context as CustomAgGridContext;
  let fieldName = '';
  if (isIndented) {
    fieldName = get(params, 'colDef.field', '');
  } else {
    // @ts-expect-error params.colDef can be a single column or a row group, but the type doesn't include specific fields from either
    fieldName = params?.colDef?.field || params?.colDef?.showRowGroup;
  }
  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);
  if (!localColumnMetadata) {
    return '';
  }
  const customHeaderStyle = getCustomHeaderStyle(columnFormats[fieldName]);

  return [customHeaderStyle, 'ag-grid-header'];
};

const getCellStyle = (params: CellClassParams) => {
  const { showSubTotal, nonStandardAggregations, columnMetadata, columnFormats, currentRowStripeStyle } =
    params.context as CustomAgGridContext;
  const fieldName = getFieldName(params);
  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);
  if (!localColumnMetadata || !fieldName) {
    return {};
  }
  const customCellStyle = getCustomCellStyle({
    column: localColumnMetadata,
    nonStandardAggregations: nonStandardAggregations,
    columnFormat: columnFormats[fieldName],
    params,
    showSubTotal,
    currentRowStripeStyle
  });
  return customCellStyle;
};

const getTooltipValue = (params: ITooltipParams) => {
  if (!params.value) return undefined;
  return params.value;
};

const getValue = (params: ValueGetterParams, isIndented?: boolean) => {
  // If we have a total row we want the first grouped column to have "Total" as the value
  const columnDefs: ColDef[] | undefined = params?.api?.getColumnDefs();
  if (!columnDefs) return;

  const fieldName = getFieldName(params);
  if (params.node?.isRowPinned()) {
    if (!isIndented) {
      const firstGroupedColumn = find(columnDefs, ['rowGroupIndex', 0]);
      const firstGroupedColumnField = firstGroupedColumn?.field;
      if (fieldName === firstGroupedColumnField) return I18n.t('shared.visualizations.charts.table.total');
    } else {
      return I18n.t('shared.visualizations.charts.table.total');
    }
  }

  const nodeField = params.node?.field;
  if (params.node?.footer && fieldName && fieldName === nodeField) {
    const noValueText = I18n.t('shared.visualizations.charts.common.summary_table.no_value');
    const value = params.getValue(fieldName);
    return value == NULL_GROUPING ? noValueText : value;
  }
};

const getCellClass = (params: CellClassParams) => {
  const cellClassesParams = {
    colFieldName: params.column.getColId(),
    colDataTypeName: '',
    node: params.node,
    context: params.context,
    value: params.value
  };
  return generateCellClasses(cellClassesParams);
};

const getCellClassRules = (params: CellClassParams, rule: string) => {
  const colId = params.colDef?.colId;
  if (!colId) {
    return false;
  }
  return params.context.columnFormats[colId]?.format.align == rule;
};

export const useAutoGroupAttributes = ({
  datasetUid,
  domain,
  isIndented,
  singleAutoColumnWidth
}: AutoGroupAttributesProps) => {
  return useMemo<ColDef>(() => {
    const width = isIndented ? singleAutoColumnWidth : undefined;
    return {
      headerTooltip: 'Group',
      headerValueGetter: (params: HeaderValueGetterParams) => {
        return getHeaderValue(params, isIndented);
      },
      tooltipComponentParams: {
        domain: domain,
        datasetUid: datasetUid,
        isIndented: isIndented
      },
      headerClass: (params: HeaderClassParams) => {
        return getHeaderClass(params, isIndented);
      },
      cellStyle: getCellStyle,
      tooltipValueGetter: getTooltipValue,
      cellRendererParams: {
        innerRenderer: GroupAndTotalRenderer,
        innerRendererParams: {
          datasetUid,
          domain,
          wrapWithDivElement: false
        }
      },
      valueGetter: (params: ValueGetterParams) => {
        return getValue(params, isIndented);
      },
      width,
      cellClass: getCellClass,
      cellClassRules: {
        'total-row': (params) => params.node.isRowPinned(),
        'left-align': (params) => getCellClassRules(params, 'left'),
        'center-align': (params) => getCellClassRules(params, 'center'),
        'right-align': (params) => getCellClassRules(params, 'right')
      }
    } as ColDef;
  }, [datasetUid, domain, isIndented, singleAutoColumnWidth]);
};
