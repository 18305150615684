import { camelCase } from 'lodash';
import {
  SoQLType,
  FunSpec,
  Generic as VariableType,
  Fixed as FixedType,
  Wildcard as WildcardType
} from 'common/types/soql';
import { buildBuilder, buildWindowFunctionBuilder, CallableFunc, CallableWindowFunc } from './build';

type SoQLTypeClass = SoQLType[];
const ordered: SoQLTypeClass = [
  SoQLType.SoQLTextT,
  SoQLType.SoQLNumberT,
  SoQLType.SoQLBooleanT,
  SoQLType.SoQLBooleanAltT,
  SoQLType.SoQLFixedTimestampT,
  SoQLType.SoQLFloatingTimestampT
];

const geospatiallike: SoQLTypeClass = [
  SoQLType.SoQLPointT,
  SoQLType.SoQLMultiPointT,
  SoQLType.SoQLLineT,
  SoQLType.SoQLMultiLineT,
  SoQLType.SoQLPolygonT,
  SoQLType.SoQLMultiPolygonT
];

const equatable: SoQLTypeClass = ordered.concat(geospatiallike);
const numlike: SoQLTypeClass = [SoQLType.SoQLNumberT];
const realnumlike: SoQLTypeClass = [SoQLType.SoQLNumberT];

const allTypes: SoQLTypeClass = [
  SoQLType.SoQLNumberT,
  SoQLType.SoQLTextT,
  SoQLType.SoQLBooleanT,
  SoQLType.SoQLBooleanAltT,
  SoQLType.SoQLFixedTimestampT,
  SoQLType.SoQLFixedTimestampAltT,
  SoQLType.SoQLFloatingTimestampT,
  SoQLType.SoQLFloatingTimestampAltT,
  SoQLType.SoQLURLT,
  SoQLType.SoQLLocationT,
  SoQLType.SoQLPointT,
  SoQLType.SoQLLineT,
  SoQLType.SoQLPolygonT,
  SoQLType.SoQLMultiPointT,
  SoQLType.SoQLMultiLineT,
  SoQLType.SoQLMultiPolygonT
];

const fixed = (type: SoQLType): FixedType => ({ type, kind: 'fixed' });
const variable = (type: string): VariableType => ({ type, kind: 'variable' });
const wildcard = (): WildcardType => ({ type: '?', kind: 'wildcard' });

interface PartialFunSpec
  extends Required<Pick<FunSpec, 'name' | 'result'>>,
    Partial<Pick<FunSpec, 'identity' | 'constraints' | 'sig' | 'variadic' | 'operator' | 'is_aggregate'>> {}

const buildFunSpec = (spec: PartialFunSpec): FunSpec => ({
  identity: spec.identity || spec.name,
  constraints: {},
  sig: [],
  variadic: [],
  operator: false,
  is_aggregate: false,
  ...spec
});

const operators: string[] = [];
const f = (spec: PartialFunSpec): CallableFunc => {
  if (spec.operator) {
    operators.push(spec.name);
  }
  return buildBuilder(buildFunSpec(spec));
};

const field = (source: SoQLType, property: string, result: SoQLType): CallableFunc =>
  f({
    identity: `${source}_${property}`,
    name: `${source}_${property}`,
    sig: [fixed(source)],
    variadic: [],
    result: fixed(result)
  });

const wf = (spec: PartialFunSpec): CallableWindowFunc => {
  if (!spec.identity && /\w+/.test(spec.name)) {
    spec.identity = camelCase(spec.name.toLowerCase());
  }
  return buildWindowFunctionBuilder(buildFunSpec(spec));
};

const operator = (arg: string) => `op$${arg}`;
const cast = (arg: string) => `cast$${arg}`;

const soqlFunctionRegistry = {
  // this isn't right
  cast: (to: SoQLType) =>
    f({
      name: cast(to),
      sig: [variable('a')],
      result: fixed(to),
      operator: true
    }),

  // this isn't right
  subscript: f({
    name: operator('[]'),
    sig: [variable('a'), fixed(SoQLType.SoQLTextT)],
    result: variable('b'),
    operator: true
  }),

  /**
   * @remarks Accepts two arguments, which must both resolve to booleans. Will resolve to a boolean.
   */
  and: f({
    name: operator('and'),
    sig: [fixed(SoQLType.SoQLBooleanT), fixed(SoQLType.SoQLBooleanT)],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments, which must both resolve to booleans. Will resolve to a boolean.
   */
  or: f({
    name: operator('or'),
    sig: [fixed(SoQLType.SoQLBooleanT), fixed(SoQLType.SoQLBooleanT)],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts one arguments, which must resolve to a boolean. Will resolve to a boolean.
   */
  not: f({
    name: operator('not'),
    sig: [fixed(SoQLType.SoQLBooleanT)],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),

  /**
   * @remarks Accepts any two arguments. Will resolve to a text.
   */
  concat: f({
    name: operator('||'),
    sig: [variable('a'), variable('b')],
    result: fixed(SoQLType.SoQLTextT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments of the same ordered type. Will resolve to a boolean.
   */
  gte: f({
    name: operator('>='),
    constraints: { a: ordered },
    sig: [variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments of the same ordered type. Will resolve to a boolean.
   */
  gt: f({
    name: operator('>'),
    constraints: { a: ordered },
    sig: [variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments of the same ordered type. Will resolve to a boolean.
   */
  lt: f({
    name: operator('<'),
    constraints: { a: ordered },
    sig: [variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments of the same ordered type. Will resolve to a boolean.
   */
  lte: f({
    name: operator('<='),
    constraints: { a: ordered },
    sig: [variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments of the same equatable type. Will resolve to a boolean.
   */
  eq: f({
    name: operator('='),
    constraints: { a: equatable },
    sig: [variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts two arguments of the same equatable type. Will resolve to a boolean.
   */
  neq: f({
    name: operator('<>'),
    constraints: { a: equatable },
    sig: [variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT),
    operator: true
  }),
  /**
   * @remarks Accepts one argument, plus a variadic composed of the same type. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/in.html
   */
  in: f({
    name: '#IN',
    sig: [variable('a')],
    variadic: [variable('a')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts one argument, plus a variadic composed of the same type. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/not_in.html
   */
  notIn: f({
    name: '#NOT_IN',
    sig: [variable('a')],
    variadic: [variable('a')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),

  /**
   * @remarks Accepts two arguments that both resolve to a text. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/like.html
   */
  like: f({
    name: '#LIKE',
    sig: [fixed(SoQLType.SoQLTextT), fixed(SoQLType.SoQLTextT)],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts two arguments that both resolve to a text. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/not_like.html
   */
  notLike: f({
    name: '#NOT_LIKE',
    sig: [fixed(SoQLType.SoQLTextT), fixed(SoQLType.SoQLTextT)],
    result: fixed(SoQLType.SoQLBooleanT)
  }),

  /**
   * @remarks Accepts two arguments that both resolve to a text. Will resolve to a boolean.
   */
  contains: f({
    name: 'contains',
    sig: [fixed(SoQLType.SoQLTextT), fixed(SoQLType.SoQLTextT)],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts two arguments that both resolve to a text. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/starts_with.html
   */
  startsWith: f({
    name: 'starts_with',
    sig: [fixed(SoQLType.SoQLTextT), fixed(SoQLType.SoQLTextT)],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts one arguments that resolves to a text. Will resolve to a text.
   * @see https://dev.socrata.com/docs/functions/lower.html
   */
  lower: f({
    name: 'lower',
    sig: [fixed(SoQLType.SoQLTextT)],
    result: fixed(SoQLType.SoQLTextT)
  }),
  /**
   * @remarks Accepts one arguments that resolves to a text. Will resolve to a text.
   * @see https://dev.socrata.com/docs/functions/upper.html
   */
  upper: f({
    name: 'upper',
    sig: [fixed(SoQLType.SoQLTextT)],
    result: fixed(SoQLType.SoQLTextT)
  }),

  /**
   * @remarks Accepts a geospatial type argument and a realnum type argument. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/within_circle.html
   */
  withinCircle: f({
    name: 'within_circle',
    constraints: { a: geospatiallike, b: realnumlike },
    sig: [variable('a'), variable('b'), variable('b'), variable('b')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts a geospatial type argument and a realnum type argument. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/within_box.html
   */
  withinBox: f({
    name: 'within_box',
    constraints: { a: geospatiallike, b: realnumlike },
    sig: [variable('a'), variable('b'), variable('b'), variable('b')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts a geospatial type argument. Will resolve to a multipolygon.
   * @see https://dev.socrata.com/docs/functions/extent.html
   */
  extent: f({
    name: 'extent',
    constraints: { a: geospatiallike },
    sig: [variable('a')],
    result: fixed(SoQLType.SoQLMultiPolygonT),
    is_aggregate: true
  }),
  /**
   * @remarks Accepts a geospatial type argument and a number type argument. Will resolve to a geospatial type.
   * @see https://dev.socrata.com/docs/functions/snap_to_grid.html
   */
  snapToGrid: f({
    name: 'snap_to_grid',
    constraints: { a: geospatiallike, b: numlike },
    sig: [variable('a'), variable('b')],
    result: variable('a')
  }),

  /**
   * @remarks Accepts no arguments. Will resolve to a number.
   */
  rowNumber: f({
    name: 'row_number',
    sig: [],
    result: fixed(SoQLType.SoQLNumberT)
  }),

  /**
   * @remarks Accepts an argument of any type. Will resolve to a boolean.
   */
  isNull: f({
    name: '#IS_NULL',
    sig: [variable('a')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts an argument of any type. Will resolve to a boolean.
   */
  isNotNull: f({
    name: '#IS_NOT_NULL',
    sig: [variable('a')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),

  /**
   * @remarks Accepts three arguments of any ordered type. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/between.html
   */
  between: f({
    name: '#BETWEEN',
    constraints: { a: ordered },
    sig: [variable('a'), variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),
  /**
   * @remarks Accepts three arguments of any ordered type. Will resolve to a boolean.
   * @see https://dev.socrata.com/docs/functions/not_between.html
   */
  notBetween: f({
    name: '#NOT_BETWEEN',
    constraints: { a: ordered },
    sig: [variable('a'), variable('a'), variable('a')],
    result: fixed(SoQLType.SoQLBooleanT)
  }),

  /**
   * @remarks Accepts one argument of any ordered type. Will resolve to a response of the same type.
   * @see https://dev.socrata.com/docs/functions/min.html
   */
  min: f({
    name: 'min',
    constraints: { a: ordered },
    sig: [variable('a')],
    result: variable('a'),
    is_aggregate: true
  }),
  /**
   * @remarks Accepts one argument of any ordered type. Will resolve to a response of the same type.
   * @see https://dev.socrata.com/docs/functions/max.html
   */
  max: f({
    name: 'max',
    constraints: { a: ordered },
    sig: [variable('a')],
    result: variable('a'),
    is_aggregate: true
  }),
  /**
   * @remarks Accepts one argument of any type. Will resolve to a number.
   * @see https://dev.socrata.com/docs/functions/count.html
   */
  count: f({
    name: 'count',
    sig: [variable('a')],
    result: fixed(SoQLType.SoQLNumberT),
    is_aggregate: true
  }),
  /**
   * @remarks Accepts one argument of any type. Will resolve to a number.
   */
  countDistinct: f({
    name: 'count_distinct',
    sig: [variable('a')],
    result: fixed(SoQLType.SoQLNumberT),
    is_aggregate: true
  }),
  /**
   * @remarks Accepts one argument of any numeric type. Will resolve to a number.
   * @see https://dev.socrata.com/docs/functions/avg.html
   */
  avg: f({
    name: 'avg',
    constraints: { a: numlike },
    sig: [variable('a')],
    result: variable('a'),
    is_aggregate: true
  }),
  /**
   * @remarks Accepts one argument of any numeric type. Will resolve to a number.
   * @see https://dev.socrata.com/docs/functions/sum.html
   */
  sum: f({
    name: 'sum',
    constraints: { a: numlike },
    sig: [variable('a')],
    result: variable('a'),
    is_aggregate: true
  }),

  /**
   * @remarks Accepts one argument of any numeric type. Will resolve to a number.
   */
  unaryPlus: f({
    identity: 'unaryPlus',
    name: 'unary +',
    constraints: { a: numlike },
    sig: [variable('a')],
    result: variable('a')
  }),
  /**
   * @remarks Accepts one argument of any numeric type. Will resolve to a number.
   */
  unaryMinus: f({
    identity: 'unaryMinus',
    name: 'unary -',
    constraints: { a: numlike },
    sig: [variable('a')],
    result: variable('a')
  }),

  /**
   * @remarks Accepts two arguments of any numeric type. Will resolve to a number.
   */
  binaryPlus: f({
    identity: 'binaryPlus',
    name: '+',
    constraints: { a: numlike },
    sig: [variable('a'), variable('a')],
    result: variable('a')
  }),
  /**
   * @remarks Accepts two arguments of any numeric type. Will resolve to a number.
   */
  binaryMinus: f({
    identity: 'binaryMinus',
    name: '-',
    constraints: { a: numlike },
    sig: [variable('a'), variable('a')],
    result: variable('a')
  }),

  /**
   * @remarks Accepts two arguments of any numeric type. Will resolve to a number.
   */
  timesNumNum: f({
    identity: 'timesNumNum',
    name: '*NN',
    constraints: { a: numlike },
    sig: [variable('a'), variable('a')],
    result: variable('a')
  }),
  /**
   * @remarks Accepts two arguments of any numeric type. Will resolve to a number.
   */
  divNumNum: f({
    identity: 'divNumNum',
    name: '/NN',
    constraints: { a: numlike },
    sig: [variable('a'), variable('a')],
    result: variable('a')
  }),
  /**
   * @remarks Accepts two arguments of any numeric type. Will resolve to a number.
   */
  expNumNum: f({
    identity: 'expNumNum',
    name: '^NN',
    constraints: { a: numlike },
    sig: [variable('a'), variable('a')],
    result: variable('a')
  }),
  /**
   * @remarks Accepts two arguments of any numeric type. Will resolve to a number.
   */
  modNumNum: f({
    identity: 'modNumNum',
    name: '%NN',
    constraints: { a: numlike },
    sig: [variable('a'), variable('a')],
    result: variable('a')
  }),

  /**
   * @remarks Accepts one argument of type calendar_date. Will resolve to a type calendar_date.
   */
  date_trunc_ymd: f({
    identity: 'floating timestamp trunc day',
    name: 'date_trunc_ymd',
    sig: [fixed(SoQLType.SoQLFloatingTimestampT)],
    result: fixed(SoQLType.SoQLFloatingTimestampT)
  }),
  /**
   * @remarks Accepts one argument of type calendar_date. Will resolve to a number.
   */
  date_extract_y: f({
    identity: 'floating timestamp extract year',
    name: 'date_extract_y',
    sig: [fixed(SoQLType.SoQLFloatingTimestampT)],
    result: fixed(SoQLType.SoQLNumberT)
  }),
  /**
   * @remarks Accepts one argument of type calendar_date. Will resolve to a number.
   */
  date_extract_m: f({
    identity: 'floating timestamp extract month',
    name: 'date_extract_m',
    sig: [fixed(SoQLType.SoQLFloatingTimestampT)],
    result: fixed(SoQLType.SoQLNumberT)
  }),
  /**
   * @remarks Accepts one argument of type calendar_date. Will resolve to a number.
   */
  date_extract_iso_y: f({
    identity: 'floating timestamp extract iso year',
    name: 'date_extract_iso_y',
    sig: [fixed(SoQLType.SoQLFloatingTimestampT)],
    result: fixed(SoQLType.SoQLNumberT)
  }),
  /**
   * @remarks Accepts one argument of type calendar_date. Will resolve to a number.
   */
  date_extract_woy: f({
    identity: 'floating timestamp extract week of year',
    name: 'date_extract_woy',
    sig: [fixed(SoQLType.SoQLFloatingTimestampT)],
    result: fixed(SoQLType.SoQLNumberT)
  }),

  /**
   * Take the leftmost non-null value
   * @remarks Accepts at least one argument of the same type. Will resolve to that type.
   */
  coalesce: f({
    identity: 'coalesce',
    name: 'coalesce',
    sig: [variable('a')],
    variadic: [variable('a')],
    result: variable('a')
  }),

  /**
   * Get the description of a url.
   * @remarks Accepts a url. Will resolve to a text.
   */
  url_description: field(SoQLType.SoQLURLT, 'description', SoQLType.SoQLTextT),

  /**
   * Get the url of a url.
   * @remarks Accepts a url. Will resolve to a text.
   */
  url_url: field(SoQLType.SoQLURLT, 'url', SoQLType.SoQLTextT),

  /**
   * Get the latitude of a location.
   * @remarks Accepts a location. Will resolve to a number.
   */
  location_latitude: field(SoQLType.SoQLLocationT, 'latitude', SoQLType.SoQLNumberT),

  /**
   * Get the longitude of a location.
   * @remarks Accepts a location. Will resolve to a number.
   */
  location_longitude: field(SoQLType.SoQLLocationT, 'longitude', SoQLType.SoQLNumberT),

  /**
   * Get the human address of a location.
   * @remarks Accepts a location. Will resolve to a text.
   */
  location_human_address: field(SoQLType.SoQLLocationT, 'human_address', SoQLType.SoQLTextT),

  /**
   * Get the latitude of a point.
   * @remarks Accepts a point. Will resolve to a number.
   */
  point_latitude: field(SoQLType.SoQLPointT, 'latitude', SoQLType.SoQLNumberT),

  /**
   * Get the longitude of a point.
   * @remarks Accepts a point. Will resolve to a number.
   */
  point_longitude: field(SoQLType.SoQLPointT, 'longitude', SoQLType.SoQLNumberT)
};

/**
 * @param args_0 An Expression that resolves to any type.
 * @param args_1 Response from a windowFunc invocation.
 * @see https://www.postgresql.org/docs/9.6/tutorial-window.html
 */
const buildWindowFunctionOver = wf({
  name: '#WF_OVER',
  constraints: { a: allTypes },
  sig: [variable('a')],
  variadic: [wildcard()],
  result: variable('a')
});

export { operators };
export { buildWindowFunctionOver as wfOver };
export { soqlFunctionRegistry as default };
