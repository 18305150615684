import React from 'react';
import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  CellClassParams,
  ValueGetterParams,
  SortDirection,
  ColumnMenuTab,
  HeaderClassParams
} from '@ag-grid-community/core';

import { FeatureFlags } from 'common/feature_flags';

import { ViewColumn } from 'common/types/viewColumn';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { Hierarchy, HierarchyColumnConfig, OrderConfig } from 'common/visualizations/vif';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { isUpdatedConditionalFormattingDesignsEnabled } from 'common/visualizations/helpers/VifSelectors';

import { getAgColFilterObject } from 'common/visualizations/helpers/AgGridHelpers';
import {
  getAgTableHeaderName,
  getAllowedColumnAggregationFunctions,
  getCustomCellStyle,
  getCustomHeaderStyle,
  getFieldName
} from './TableColumnFormatting';
import { ValueRenderer } from '../ValueRenderer';
import { NULL_GROUPING } from '../Constants';
import { isGeospatial } from 'common/types/soql';
import { generateCellClasses } from './TableExportFormatting';
import { GROUPABLE_DATA_TYPES } from 'common/authoring_workflow/constants';

function getColumnSort(column: ViewColumn, orders: OrderConfig[]) {
  const orderIndex = orders.findIndex((o: OrderConfig) => column.fieldName === o.columnName);
  let columnIsSorted = false;
  let columnSort: SortDirection | undefined = undefined;
  let sortIndex = null;

  if (orderIndex !== -1) {
    columnIsSorted = true;
    columnSort = orders[orderIndex].ascending ? 'asc' : 'desc';
    sortIndex = orderIndex;
  }

  return { columnIsSorted, columnSort, sortIndex };
}

interface IComputeAgColumnParams {
  column: ViewColumn;
  viewColumnMetadata: ViewColumn;
  columnFormats: { [key: string]: TableColumnFormat };
  dataFormatter?: (params: ICellRendererParams) => React.ReactElement;
  datasetUid: string;
  displayColumnFilters?: boolean;
  domain: string;
  hierarchyColumns?: HierarchyColumnConfig[];
  hierarchyConfig?: Hierarchy;
  nonStandardAggregations?: ColumnAggregation[] | null;
  removedHierarchyColumnNames?: Set<string>;
  showAgGridColumnAggregations?: boolean;
  showAgGridColumnMenu?: boolean;
  useSetFilters?: boolean;
  vifOrderConfig: OrderConfig[];
}

const valueGetterFunction = (params: ValueGetterParams) => {
  if (typeof params.data === 'undefined') {
    return;
  }
  const ctx = params.context as CustomAgGridContext;

  const { groupedColumns, showSubTotal } = ctx;
  const field = params.colDef.field ?? '';
  const isGroup = groupedColumns.includes(field);
  if (typeof params.data == 'undefined') {
    return;
  }
  const data = params.data[field];
  if (isGroup && typeof data === 'undefined') {
    return NULL_GROUPING;
  }
  // the condition below checks whether the current field
  // is part of a hierarchy row and a non-hierarchy column
  if (
    params.node?.expanded &&
    params.node?.level < groupedColumns.length &&
    !groupedColumns.includes(field) &&
    showSubTotal
  )
    return;
  return data;
};

const tooltipValueGetterFunction = (params: ITooltipParams) => {
  if (!params.value || params.value === '') return undefined;
  return params.value;
};

const cellStyleFunction = (params: CellClassParams) => {
  const ctx = params.context as CustomAgGridContext;
  const { nonStandardAggregations, columnMetadata, columnFormats, showSubTotal, currentRowStripeStyle } = ctx;
  const fieldName = getFieldName(params);
  const columnFormat = columnFormats[fieldName!];
  const localColumnMetadata = columnMetadata.find((c) => c.fieldName === fieldName);

  const customCellStyle = getCustomCellStyle({
    column: localColumnMetadata!,
    nonStandardAggregations,
    columnFormat,
    params,
    showSubTotal,
    currentRowStripeStyle
  });
  return customCellStyle;
};

const headerClassFunction = (params: HeaderClassParams) => {
  const ctx = params.context as CustomAgGridContext;
  const { columnFormats } = ctx;
  const fieldName = getFieldName(params);
  const columnFormat = columnFormats[fieldName!];

  const customHeaderStyle = getCustomHeaderStyle(columnFormat);

  return [customHeaderStyle, 'ag-grid-header'];
};

const getCellClassRules = (params: CellClassParams, rule: string) => {
  const colId = params.colDef?.colId;
  if (!colId) {
    return false;
  }
  return params.context.columnFormats[colId]?.format.align == rule;
};

export default function computeAgColumn({
  column,
  viewColumnMetadata,
  columnFormats,
  datasetUid,
  displayColumnFilters,
  domain,
  hierarchyColumns = [],
  hierarchyConfig,
  nonStandardAggregations,
  removedHierarchyColumnNames,
  showAgGridColumnAggregations = false,
  showAgGridColumnMenu = false,
  useSetFilters,
  vifOrderConfig
}: IComputeAgColumnParams): ColDef | null {
  const hierarchyConfigIdx = hierarchyColumns.findIndex(({ columnName }) => columnName === column.fieldName);
  const localHierarchyConfig = hierarchyColumns[hierarchyConfigIdx];

  const { columnIsSorted, columnSort, sortIndex } = getColumnSort(column, vifOrderConfig);

  const isColumnGrouping = localHierarchyConfig?.isGrouping ?? false;
  const isColumnHidden = (hierarchyConfig ? localHierarchyConfig?.hidden : column.hide) ?? false;
  const hierarchyAggregation = localHierarchyConfig?.aggregation ?? null;
  const shouldResetRowIndex = removedHierarchyColumnNames?.has(column.fieldName);

  // to ag-grid, `number` will define the ordering of the group columns;
  // `null` means to clear the existing value (but can only be used if there _is_ an existing value)
  // `undefined` means that this isn't a row group (it also means don't change the existing value, if present)
  let rowGroupIndex: number | null | undefined;
  if (shouldResetRowIndex) {
    rowGroupIndex = null;
  } else if (isColumnGrouping) {
    rowGroupIndex = hierarchyConfigIdx;
  }

  const columnFormat = columnFormats?.[column.fieldName];
  const displayName = getAgTableHeaderName(columnFormat, viewColumnMetadata);
  const sortable = !isGeospatial(column.dataTypeName);

  const enableFlexibleHierarchies = FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false);
  const filterObject = displayColumnFilters
    ? getAgColFilterObject({
        datasetUid,
        columnFieldName: column.fieldName,
        dataTypeName: column.dataTypeName,
        useSetFilters: !!useSetFilters
      })
    : {};

  // Only include the menuTabs property in the ColDef if requested
  const menuTabObject: { menuTabs?: ColumnMenuTab[] | undefined } = showAgGridColumnMenu
    ? { menuTabs: ['generalMenuTab', 'filterMenuTab'] }
    : {};

  const applicableNonStandardAggregations = (nonStandardAggregations ?? []).filter(
    ({ fieldName }) => fieldName === column.fieldName
  );

  const colDef: ColDef = {
    ...filterObject,
    ...menuTabObject,
    width: column.width,
    /**
     * `flex` overrides `width`, so it should only be set on columns
     * where the user has not selected a column width.
     */
    flex: column.width ? undefined : 1,
    sort: columnIsSorted ? columnSort : undefined,
    sortIndex,
    sortable,

    colId: column.fieldName,
    headerName: displayName,
    field: column.fieldName,

    allowedAggFuncs: getAllowedColumnAggregationFunctions(column, applicableNonStandardAggregations),
    aggFunc: hierarchyAggregation,
    headerTooltip: displayName,
    headerClass: headerClassFunction,
    hide: isColumnGrouping || isColumnHidden, // Otherwise AG-grid will show grouped column and original column which is redundant

    suppressColumnsToolPanel: !enableFlexibleHierarchies && isColumnGrouping, // Grouping columns shouldn't be shown in columns tool panel
    rowGroupIndex, // https://ag-grid.com/react-data-grid//column-properties/#reference-grouping-rowGroupIndex

    cellRendererParams: {
      datasetUid,
      domain,
      wrapWithDivElement: true
    },
    cellRenderer: ValueRenderer,
    cellStyle: cellStyleFunction,
    tooltipComponentParams: {
      datasetUid,
      domain
    },
    tooltipValueGetter: tooltipValueGetterFunction,
    valueGetter: valueGetterFunction,
    // this toggles our wrapped text feature
    // which is only applicable using the style
    // options found in Conditional Formatting
    ...(isUpdatedConditionalFormattingDesignsEnabled()
      ? {
          wrapText: columnFormat?.style?.fontStyle?.isWrapped,
          autoHeight: columnFormat?.style?.fontStyle?.isWrapped
        }
      : {}),
    cellClass: (params) => {
      const cellClassesParams = {
        colFieldName: column.fieldName,
        colDataTypeName: column.dataTypeName,
        node: params.node,
        context: params.context,
        value: params.value
      };
      return generateCellClasses(cellClassesParams);
    },
    cellClassRules: {
      'total-row': (params) => params.node.isRowPinned(),
      'left-align': (params) => getCellClassRules(params, 'left'),
      'center-align': (params) => getCellClassRules(params, 'center'),
      'right-align': (params) => getCellClassRules(params, 'right')
    }
  };

  if (showAgGridColumnAggregations) {
    colDef.enableRowGroup = GROUPABLE_DATA_TYPES.has(column.dataTypeName);
    colDef.enableValue = true;
    colDef.defaultAggFunc = 'count'; // 'sum' is the default aggregation for 'number' columns
  }

  return colDef;
}
