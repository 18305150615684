import { RowStripeStyle } from 'common/types/agGrid/rowStripe';
import { Expression, FormatStyle, TableColumnFormat } from '../../../../authoring_workflow/reducers/types';
import { isConditionMeet } from './TableColumnFormatting';
import { ViewColumn } from 'common/types/viewColumn';
import { isEmpty, isEqual, isNil } from 'lodash';
import { SoQLType } from 'common/types/soql';
import { IRowNode } from '@ag-grid-community/core';
import { GROUP_COLUMN_PREFIX } from '../Constants';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { defaultRowStripeValue } from 'common/authoring_workflow/components/panes/PresentationPane/RowStripeSelector';
import {
  DEFAULT_TABLE_HEADER_TEXT_COLOR,
  DEFAULT_TABLE_HEADER_BACKGROUND_COLOR
} from 'common/authoring_workflow/constants';
import { isColumnStylingInTablesEnabled } from 'common/visualizations/helpers/VifSelectors';

export interface IExcelStylesParams {
  id: string;
  alignment?: { horizontal: string };
  font?: { color?: string; italic?: boolean; bold?: boolean };
  interior?: { color: string; pattern: string };
}

interface ITableExportFormattingParams {
  columnFormats: { [key: string]: TableColumnFormat };
  rowStripeStyle: RowStripeStyle | undefined;
  columnMetadata: ViewColumn[];
  headerFormat: FormatStyle;
}

/**  excelStyles in gridOptions expects an array of style objects, each of which should include
 * an id property (which corresponds to the className) that matches the values generated by generateCellClasses.
 * @returns ```
 * [
 *   {
 *       id: "style-option-<fieldname>-",
 *       font: { color: "#e0ffc1"},
 *       interior: {
 *          color: "#008000", pattern: 'Solid'
 *       }
 *   }
 * ]```;
 */
export const generateExcelStyles = (params: ITableExportFormattingParams) => {
  const columnFormats = params.columnFormats;
  let excelStyles: IExcelStylesParams[] = generateCommonCellClasses(params.headerFormat);
  excelStyles = [
    ...generateDefaultStyle(params.columnMetadata),
    ...excelStyles,
    ...generateRowStriping(params.rowStripeStyle)
  ];

  Object.keys(columnFormats).forEach((colKey) => {
    excelStyles.push(generateStyleOption(colKey, styleAttributes(columnFormats[colKey]), 'style-option'));

    if (columnFormats[colKey].isAlignHeader) {
      const alignment = columnFormats[colKey].format?.align ?? '';
      excelStyles.push(generateHeaderAlignment(alignment));
    }

    const conditionalConfigs = columnFormats[colKey].conditionalConfig;
    conditionalConfigs?.forEach((config, index) => {
      if (index > 0) {
        const isSameCondition = isEqual(conditionalConfigs[index - 1].expression, config.expression);
        if (isSameCondition) return;
      }
      excelStyles.push(generateStyleOption(colKey, styleAttributes(config), 'conditional-config', index));
    });
  });

  return excelStyles;
};

export interface ICellClassesParams {
  colFieldName: string;
  colDataTypeName: string;
  node: IRowNode;
  context: CustomAgGridContext;
  value: string | number;
}

/**  cellClass in columnDef expects an array of class names (corresponding to the IDs) that match the ID values generated by generateExcelStyles.
 * The classNames are dependent to columnFormatting values.
 * @returns ```
 * ['conditional-config-<fieldname>-0', 'conditional-config-<fieldname>-1', 'style-option-<fieldname>']```;
 */
export const generateCellClasses = (params: ICellClassesParams) => {
  let styleClasses: string[] = [];
  let rowStripeClass = 'base-row';

  if (params.node.rowIndex) {
    rowStripeClass = params.node.rowIndex % 2 !== 0 ? 'alternate-row' : 'base-row';
  }
  styleClasses = [rowStripeClass];
  const columnName = params.colFieldName.replace(`${GROUP_COLUMN_PREFIX}-`, '');
  styleClasses.unshift(`default-style-${columnName}`);

  const hasBaseStyle = params.context.columnFormats[columnName];
  if (!!hasBaseStyle && params.value && !params.node.isRowPinned() && !params.node.footer) {
    styleClasses.unshift(`style-option-${columnName}`);
  }

  const conditionalConfig = params.context.columnFormats[columnName]?.conditionalConfig;
  if (conditionalConfig) {
    const configClasses: string[] = [];
    let withConditionalConfigClass = false;
    conditionalConfig.forEach((config: { expression: Expression }, index: number) => {
      const expression = config.expression;
      if (index > 0) {
        const isSameCondition = isEqual(conditionalConfig[index - 1].expression, expression);
        if (isSameCondition) return;
      }
      const conditionMeet = isConditionMeet(
        expression,
        params.value,
        isEqual(SoQLType.SoQLNumberT, params.colDataTypeName)
      );

      if (conditionMeet && !withConditionalConfigClass) {
        withConditionalConfigClass = true;
        configClasses.push(`conditional-config-${columnName}-${index}`);
      }
    });
    styleClasses = [...configClasses, ...styleClasses];
  }
  return styleClasses;
};

const generateRowStriping = (rowStripeStyle: RowStripeStyle | undefined) => {
  if (!isNil(rowStripeStyle) && !isEmpty(rowStripeStyle)) {
    return ['base', 'alternate'].map((type) => {
      const stripeStyle = rowStripeStyle[type];
      const style = {
        id: `${type}-row`,
        font: {
          color: stripeStyle?.text || '#000'
        }
      };
      const interiorStyle = {
        interior: {
          color: stripeStyle.fill,
          pattern: 'Solid'
        }
      };
      if (!isEqual(rowStripeStyle, defaultRowStripeValue)) {
        return { ...style, ...interiorStyle };
      } else {
        return style;
      }
    });
  }
  return [];
};

const generateDefaultStyle = (viewColumns: ViewColumn[]) => {
  return viewColumns.map((viewColumn: ViewColumn) => {
    let alignment = 'Left';
    if (viewColumn.renderTypeName === SoQLType.SoQLNumberT) {
      alignment = 'Right';
    } else if ([SoQLType.SoQLBooleanT, SoQLType.SoQLBooleanAltT].includes(viewColumn.renderTypeName)) {
      alignment = 'Center';
    }
    return {
      id: `default-style-${viewColumn.fieldName}`,
      alignment: {
        horizontal: alignment
      }
    };
  });
};

const generateStyleOption = (colKey: string, styles: any, prefix: string, index?: number) => {
  const id = typeof index === 'number' ? `${prefix}-${colKey}-${index}` : `${prefix}-${colKey}`;
  const baseStyleOption = {
    id: id
  };
  const stylingOption = {
    font: {
      color: styles.fontColor,
      bold: styles.bold,
      italic: styles.italic
    },
    interior: {
      color: styles.backgroundColor,
      pattern: 'Solid'
    }
  };

  if (prefix === 'style-option') {
    return isColumnStylingInTablesEnabled() ? { ...baseStyleOption, ...stylingOption } : baseStyleOption;
  }

  return { ...baseStyleOption, ...stylingOption };
};

const styleAttributes = (formatObj: any) => {
  return {
    fontColor: formatObj.style?.textColor,
    backgroundColor: formatObj.style?.backgroundColor,
    bold: formatObj.style?.fontStyle?.isBold,
    italic: formatObj.style?.fontStyle?.isItalic
  };
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** headerClass in columnDef returns an array of class names - ag-grid-header included.
 * We need to use the class name - 'ag-grid-header' to match the value from columnDef headerClass
 * we don't need multiple class names since the style is same for all column headers.
 * returns a value with type IExcelStylesParams
 * sample returned value: ```
 * { id: 'ag-grid-header', font: { color: <value>, bold: <value>, italic: <value>}, interior: { color: <value>, pattern: 'Solid' }} ```;
 */
const generateHeaderStyle = (headerFormat: FormatStyle) => {
  const isBoldHeaderFontStyle = headerFormat?.fontStyle?.isBold ?? true;
  const isItalicHeaderFontStyle = headerFormat?.fontStyle?.isItalic ?? false;
  const textColor = headerFormat.textColor ?? DEFAULT_TABLE_HEADER_TEXT_COLOR;
  const backgroundColor = headerFormat.backgroundColor ?? DEFAULT_TABLE_HEADER_BACKGROUND_COLOR;
  const style = {
    id: 'ag-grid-header',
    font: {
      color: textColor,
      bold: isBoldHeaderFontStyle,
      italic: isItalicHeaderFontStyle
    }
  };
  const interiorStyle = {
    interior: {
      color: backgroundColor,
      pattern: 'Solid'
    }
  };

  if (isEqual(backgroundColor, DEFAULT_TABLE_HEADER_BACKGROUND_COLOR)) {
    return style as IExcelStylesParams;
  }

  return { ...style, ...interiorStyle } as IExcelStylesParams;
};

/** headerClass in columnDef returns an array of class names - ag-grid-header-${alignment} included.
 * We need to use that class name to match the value from columnDef headerClass
 * returns a value with type IExcelStylesParams
 * sample returned value: ```
 * { id: 'ag-grid-header-left', alignment: { horizontal: 'Left' }} ```;
 */
const generateHeaderAlignment = (alignment: string) => {
  return {
    id: `ag-grid-header-${alignment}`,
    alignment: {
      horizontal: capitalize(alignment)
    }
  } as IExcelStylesParams;
};

const generateCommonCellClasses = (headerFormat: FormatStyle) => {
  const excelStyles: any = [];
  excelStyles.push(generateHeaderStyle(headerFormat));

  // Grand Total Row
  excelStyles.push({
    id: 'total-row',
    font: {
      bold: true
    }
  });

  // Alignment
  ['Center', 'Left', 'Right'].forEach((alignment) => {
    excelStyles.push({
      id: `${alignment.toLowerCase()}-align`,
      alignment: {
        horizontal: alignment
      }
    });
  });

  return excelStyles;
};
