import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Picklist from 'common/components/Picklist';
import { handleDownArrow, handleEnter } from 'common/dom_helpers/keyPressHelpers';
import I18n from 'common/i18n';

const SEARCH_ALL = I18n.t('shared.asset_browser.filters.searchbox_filter.all');

export class SearchboxFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputDisplayText: this.props.value || ''
    };

    this.defaultValue = {
      title: '',
      value: null
    };

    // Used for onBlur's deferred function to check if the component is still mounted before updating state.
    this._isMounted = React.createRef();

    _.bindAll(this, 'onEnter', 'onInputChange', 'onInputFocus', 'onInputBlur', 'onKeyUp', 'onPicklistFocus',
      'onPicklistBlur', 'onSelection', 'getPicklistOptions', 'filterOptions');
  }

  componentDidMount() {
    this.filterOptions();
    this._isMounted.current = true;
  }

  componentWillUnmount() {
    this._isMounted.current = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    this.setState({
      inputDisplayText: (value === SEARCH_ALL ? '' : value) || ''
    });
  }

  onEnter(event) {
    event.preventDefault();

    // If user hits "enter" when the input is blank, re-fetch the original results.
    if (!event.target.value) {
      this.props.onSelection(this.defaultValue);
    }
  }

  onInputChange(event) {
    this.setState({
      inputDisplayText: event.target.value === SEARCH_ALL ? '' : event.target.value
    }, this.filterOptions);
  }

  onInputFocus() {
    this.setState({ inputFocused: true });
  }

  onInputBlur() {
    // Defer, which allows tab focus to find the picklist before it is hidden.
    _.defer(() => {
      if (this._isMounted.current) {
        this.setState({ inputFocused: false });
      }
    });
  }

  onKeyUp() {
    this.setState({ inputFocused: false, picklistFocused: true });
    this.filterInput.parentElement.querySelector('.picklist').focus();
  }

  onPicklistFocus() {
    this.setState({ picklistFocused: true });
  }

  onPicklistBlur() {
    this.setState({ picklistFocused: false });
  }

  onSelection(value) {
    this.setState({
      // When value.value === `null`, this represents the "All" pseudo-selection which is really "no filter"
      // so we set the input value state to an empty string.
      inputDisplayText: value.value === null ? '' : value.title,
      inputFocused: false,
      picklistFocused: false
    });
    this.props.onSelection(value);
    this.filterInput.blur();
  }

  getPicklistOptions() {
    return [
      { title: SEARCH_ALL, value: null }
    ].concat(this.filterOptions());
  }

  /// Only show 100 results at a time
  filterOptions() {
    if (this.props.hasClickableParentCategories) {
      return _.slice(this.filterCategories(), 0, 100);
    } else {
      return _.slice(_.filter(this.props.options, (option) => (
        _.toLower(option.title).indexOf(_.toLower(this.state.inputDisplayText)) > -1
      )), 0, 100);
    }
  }

  filterCategories() {
    let searchText = _.toLower(this.state.inputDisplayText);
    let returnedOptions = [];
    this.props.options.forEach(parentCategory => {
      if (_.toLower(parentCategory.title).includes(searchText) &&
        !returnedOptions.some(o => o.title === parentCategory.title)) {
        returnedOptions.push(parentCategory);
      } else if (parentCategory.children) {
        let childrenToAdd = [];
        parentCategory.children.forEach(childCategory => {
          if (_.toLower(childCategory.title).includes(searchText) &&
            !childrenToAdd.some(c => c.title === childCategory.title)) {
            childrenToAdd.push(childCategory);
          }
        });

        // if any children matched, add parent and then children from childrenToAdd list
        if (childrenToAdd.length > 0) {
          returnedOptions.push(parentCategory);
          returnedOptions = returnedOptions.concat(childrenToAdd);
        }
      }
    });

    if (searchText == '') {
      return this.props.options;
    } else {
      return returnedOptions;
    }
  }

  render() {
    const picklistAttributes = {
      options: this.getPicklistOptions(),
      value: this.props.value,
      ref: ref => this.picklistRef = ref,
      onSelection: this.onSelection,
      onFocus: this.onPicklistFocus,
      onBlur: this.onPicklistBlur,
      hasClickableParentCategory: this.props.hasClickableParentCategory
    };

    const picklist = this.state.inputFocused || this.state.picklistFocused ?
      <div className="picklist-wrapper">
        <Picklist {...picklistAttributes} />
      </div> : null;

    // this.props.value is the display text for the current value. What inputValue represents is the
    // actual value related to that display text.
    // For example, in the case that the selected option is: { title: 'All', value: null }
    // then this.props.value is 'All' and inputValue is null.
    const inputValue = _.get(
      _.find(this.getPicklistOptions(), (option) => (option.title === this.props.value)),
      'value'
    );

    const searchboxFilterInputClassnames = classNames('text-input', {
      'searchbox-selected': !!inputValue
    });

    return (
      <div className="searchbox-filter">
        <input
          aria-label={this.props.placeholder}
          autoComplete="off"
          className={searchboxFilterInputClassnames}
          id={this.props.inputId}
          type="text"
          ref={(input) => { this.filterInput = input; }}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          onKeyUp={handleDownArrow(this.onKeyUp)}
          onKeyDown={handleEnter(this.onEnter)}
          placeholder={this.props.placeholder}
          value={this.state.inputDisplayText} />
        <span className="search-icon socrata-icon-search" />
        {picklist}
      </div>
    );
  }
}

SearchboxFilter.propTypes = {
  inputId: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default SearchboxFilter;
